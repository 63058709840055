import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';
import checkToken from '../../util/checkToken';
import componentBuilder from '../../util/componentBuilder';
import { loginFromLocalStorage, tokenExpired } from '../../store/modules/user';

class Sustainability extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      components: (
        <div className="loading">
          <div className="text">Loading...</div>
        </div>
      )
    };
    this.main = React.createRef();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    checkToken('sustainability.json', this.props).then(
      data => {
        const comps = <Fragment>{componentBuilder(data.components)}</Fragment>;
        this.setState({
          components: comps
        });
      },
      () => {
        console.warn('token expired, return to login');
        this.props.tokenExpired();
        this.props.history.push('/login');
      }
    );
  }

  componentWillReceiveProps(nextProps) {}
  checkHash() {}

  render() {
    return (
      <div ref={this.main} className="Sustainability">
        {this.state.components}
      </div>
    );
  }
}

Sustainability.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  token: PropTypes.string,
  dispatch: PropTypes.func,
  loginFromLocalStorage: PropTypes.func,
  tokenExpired: PropTypes.func
});

Sustainability.defaultProps = {};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = dispatch => ({
  loginFromLocalStorage: token => dispatch(loginFromLocalStorage(token)),
  tokenExpired: () => dispatch(tokenExpired())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sustainability);
