import React from 'react';

const Fullscreen = props => (
  <svg data-name="Layer 1" viewBox="0 0 13 13" {...props}>
    <title>{`fullscreen`}</title>
    <path
      d="M10.77 11.61L8.24 9.07a.86.86 0 0 0-.51-.21H5.27a.86.86 0 0 0-.51.21l-2.53 2.54c-.12.12-.08.21.08.21h1.43a.59.59 0 0 1 0 1.18H.59a.58.58 0 0 1-.59-.57V9.26a.58.58 0 0 1 .57-.59.58.58 0 0 1 .59.57v1.45c0 .16.09.2.21.08l2.56-2.53a.86.86 0 0 0 .21-.51V5.27a.86.86 0 0 0-.21-.51L1.39 2.23c-.12-.12-.21-.08-.21.08v1.43a.58.58 0 0 1-.57.59.58.58 0 0 1-.61-.57V.59A.58.58 0 0 1 .57 0h3.17a.58.58 0 0 1 .59.57.58.58 0 0 1-.57.59H2.31c-.16 0-.2.09-.08.21l2.53 2.56a.86.86 0 0 0 .51.21h2.46a.86.86 0 0 0 .51-.21l2.53-2.54c.12-.12.08-.21-.08-.21H9.26a.58.58 0 0 1-.59-.57.58.58 0 0 1 .57-.61h3.17a.58.58 0 0 1 .59.57v3.17a.59.59 0 0 1-1.18 0V2.31c0-.16-.09-.2-.21-.08L9.07 4.76a.86.86 0 0 0-.21.51v2.46a.86.86 0 0 0 .21.51l2.54 2.53c.12.12.21.08.21-.08V9.26a.59.59 0 0 1 1.18 0v3.15a.58.58 0 0 1-.57.59H9.26a.59.59 0 0 1 0-1.18h1.43c.16 0 .2-.09.08-.21zM5.91 5.32a.58.58 0 0 0-.59.57v1.2a.58.58 0 0 0 .57.59h1.2a.58.58 0 0 0 .59-.57v-1.2a.58.58 0 0 0-.57-.59h-1.2z"
      fill="#dd0034"
    />
  </svg>
);

export default Fullscreen;
