import React from 'react';

const Download = props => (
  <svg viewBox="0 0 13 16" {...props}>
    <title>{`B6B16B9A-A360-4E2F-940C-A3392BE86083`}</title>
    <path
      d="M12.122 15.243H.878c-.492 0-.878-.357-.878-.81 0-.455.386-.811.878-.811h11.244c.492 0 .878.356.878.81 0 .454-.386.811-.878.811zm-.281-8.66c-.352-.324-.914-.324-1.23 0L7.378 9.569V.746C7.378.324 6.992 0 6.5 0s-.878.324-.878.746v8.822L2.389 6.584c-.351-.325-.913-.325-1.23 0-.35.324-.35.843 0 1.135l4.709 4.346c.175.162.386.227.632.227s.457-.065.632-.227l4.709-4.346c.35-.292.35-.81 0-1.135z"
      fill="#D0021B"
      fillRule="nonzero"
    />
  </svg>
);

export default Download;
