import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import './style.scss';

import checkProps from '../../util/check-props';
import Formsy from 'formsy-react';
import BasicInput from '../../components/BasicInput';
import BasicButton from '../../components/BasicButton';
import { API_URL } from '../../data/settings';
import TotalLogo from '../../components/SvgComponents/TotalLogo/TotalLogo';

export default class ForgotPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { canSubmit: false, errors: [], success: false };
    this.main = React.createRef();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
  }

  componentWillReceiveProps(nextProps) {}

  submit = model => {
    this.setState({ errors: [] });
    const obj = { password_forget: { email_username: model.email } };
    fetch(API_URL + 'password-forget', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(obj)
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response.status) {
          if (response.status === 'error') {
            this.setState({ errors: response.errors });
          } else {
            this.setState({ success: true });
          }
        }
      });
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };
  checkHash() {}

  render() {
    return (
      <section ref={this.main} className="ForgotPassword">
        <div className="content">
          <div className="form-popup">
            <TotalLogo />
            <h1>
              <span>Pure</span> Digital
              <br />
              Platform
            </h1>
            {!this.state.success && (
              <Fragment>
                <p>Please enter your email address below. You will receive a link to reset your password.</p>
                <Formsy
                  ref="register-form"
                  onValidSubmit={this.submit}
                  onValid={this.enableButton}
                  onInvalid={this.disableButton}
                >
                  <div className="form-row">
                    <BasicInput
                      label="Email or Username"
                      name="email"
                      type="text"
                      validationErrors={{
                        isDefaultRequiredValue: 'Email is required'
                      }}
                      required
                    />
                  </div>
                  <div className="row">
                    <div className="column">
                      <Link to="/login">&lt; Back to Login</Link>
                    </div>
                  </div>
                  <div className="row">
                    <BasicButton label="Submit" isSubmit={true} disabled={!this.state.canSubmit} />
                  </div>
                  {this.state.errors.length > 0 && (
                    <div className="row error">
                      {this.state.errors.map((message, i) => (
                        <p key={i}>{parse(message)}</p>
                      ))}
                    </div>
                  )}
                </Formsy>
              </Fragment>
            )}
            {this.state.success && (
              <Fragment>
                <p className="success-form">Thank you. An email has been sent to your inbox.</p>
                <div className="row">
                  <div className="column">
                    <Link to="/login">&lt; Back to Login</Link>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ForgotPassword.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func
});

ForgotPassword.defaultProps = {};
