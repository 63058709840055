import { DOWNLOADING_APP, APP_NEEDS_UPDATE } from '../keys';

const defaultState = {
  toggle: false,
  needsUpdate: false
};

export default function downloadAppReducer(state = defaultState, action) {
  switch (action.type) {
    case DOWNLOADING_APP:
      return { ...state, needsUpdate: false, toggle: action.toggle };
    case APP_NEEDS_UPDATE:
      return { ...state, needsUpdate: true };
    default:
      return state;
  }
}

export function toggleDownloadApp(toggle) {
  return { type: DOWNLOADING_APP, toggle };
}

export function pwaNeedsUpdate() {
  return { type: APP_NEEDS_UPDATE };
}
