import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import parse from 'html-react-parser';
import checkProps from '../../util/check-props';
import VideoPlayer from '../VideoPlayer';

export default class TestimonySection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.main = React.createRef();
    this.video = React.createRef();
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <section ref={this.main} className="TestimonySection">
        <div className="content">
          <div className="text">
            <h3>{this.props.title}</h3>
            <h4>{this.props.testimony}</h4>
            <p>{parse(this.props.expertDescr)}</p>
          </div>
        </div>
        <div
          className={`background-image ${!this.props.videoSRC ? 'visual-only' : ''}`}
          style={{ backgroundImage: `url(${this.props.imageSRC})` }}
        >
          {this.props.videoSRC ? (
            <div className="video-wrapper">
              <VideoPlayer posterSRC={this.props.imageSRC} videoSRC={this.props.videoSRC} minHeight="100%" />
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

TestimonySection.propTypes = checkProps({
  dispatch: PropTypes.func,
  imageSRC: PropTypes.string,
  videoSRC: PropTypes.string,
  title: PropTypes.node,
  testimony: PropTypes.node,
  expertDescr: PropTypes.node
});

TestimonySection.defaultProps = {};
