import React from 'react';

const ArrowParallaxBottom = props => (
  <svg viewBox="0 0 7 86" {...props}>
    <title>{`1893958C-A361-40B5-AEC5-1C320046BBDF`}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M3.492 82.051V1.196"
        stroke="#DF0C36"
        strokeWidth={2}
        strokeLinecap="square"
      />
      <path fill="#DF0C36" d="M3.494 85.838L0 81h6.953z" />
    </g>
  </svg>
);

export default ArrowParallaxBottom;
