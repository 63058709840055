import React from 'react';
import './style.scss';
// import BeforeAfter from '../BeforeAfter';
import CanBrown from '../SvgComponents/CanBrown/CanBrown';
import DropGold from '../SvgComponents/DropGold/DropGold';
import PropTypes from 'prop-types';

import checkProps from '../../util/check-props';

export default class SustainBeforeAfter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.posList = ['top-left', 'top-right', 'bottom-right', 'bottom-left'];
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  addCircle(pos, data, i) {
    return (
      <div key={i} className={`small-circle ${pos}`}>
        <div className="pictos">
          {data.icons.map((ic, j) => {
            if (ic === 'gold') return <DropGold key={j} />;
            else return <CanBrown key={j} />;
          })}
        </div>
        <h4>{data.title}</h4>
        <ul>
          {data.sellingPoints.map((s, j) => (
            <li key={j}>{s}</li>
          ))}
        </ul>
      </div>
    );
  }

  render() {
    return (
      <section className="SustainBeforeAfter">
        <div className="big-round" />
        <div className="content">
          <h3 className="regular-title sub-content">{this.props.title}</h3>
          <h4 className="regular-subtitle sub-content">{this.props.subtitle}</h4>
        </div>
        <div className="section-sustain-wrapper">
          <div className="clip-beforeafter">
            <img src={this.props.pictureSRC} alt="" />
            {/* <BeforeAfter
              domContent={true}
              addClassName="oil-compare"
              limitMin={30}
              limitMax={70}
              beforeContent={
                <div className="before-oil">
                  <img src={this.props.beforeSRC} />
                </div>
              }
              afterContent={
                <div className="after-oil">
                  <img src={this.props.afterSRC} />
                </div>
              }
            /> */}
          </div>

          <div className="all-circles-wrapper">
            <div className="small-circle-wrapper">
              {this.props.circles.map((c, i) => this.addCircle(this.posList[i], c, i))}
            </div>
          </div>
        </div>
        <div className="caption-wrapper">
          <div className="caption">
            <div>
              <DropGold /> {this.props.captions.gold}
            </div>
            <div>
              <CanBrown /> {this.props.captions.brown}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

SustainBeforeAfter.propTypes = checkProps({
  title: PropTypes.string,
  subtitle: PropTypes.string,
  pictureSRC: PropTypes.string,
  circles: PropTypes.array,
  captions: PropTypes.object
});

SustainBeforeAfter.defaultProps = {};
