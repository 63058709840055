import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import './style.scss';

import checkProps from '../../util/check-props';
import ProductDesignFrame from '../ProductDesignFrame';
import ProductDesignPopElement from '../ProductDesignPopElement';
import ProductDesignFreeElement from '../ProductDesignFreeElement';
import BasicButton from '../BasicButton';

import ArrowParallaxBottom from '../SvgComponents/ArrowParallaxBottom/ArrowParallaxBottom';
import ArrowParallaxTop from '../SvgComponents/ArrowParallaxTop/ArrowParallaxTop';
import Pdf from '../SvgComponents/Pdf/Pdf';
import { goTo } from '../../util/utilFn';

class ProductDesignSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { perc: 0, scroll: 0, fullHeight: 0, toggleActive: '' };
    this.rafID = -1;
    this.nbFrame = 8;
    this.main = React.createRef();
    this.parallaxWrapper = React.createRef();
    this.topContent = React.createRef();
  }

  componentDidMount() {
    this.updateFrame();
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.viewPortWidth !== this.props.viewPortWidth ||
      prevProps.viewPortHeight !== this.props.viewPortHeight ||
      this.state.fullHeight === 0
    ) {
      this.setState({ fullHeight: this.props.viewPortHeight * this.nbFrame * 2 });
    }
  }

  componentWillUnmount() {
    this.setState({ fullHeight: 0 });
    cancelAnimationFrame(this.rafID);
  }

  updateFrame = () => {
    this.rafID = requestAnimationFrame(this.updateFrame);
    this.updatePosition();
  };

  updatePosition = () => {
    const scroll =
      (window.scrollY || window.pageYOffset) - (this.main.current.offsetTop + this.topContent.current.offsetHeight);
    const perc =
      Math.min(1, Math.max(0, (scroll + window.innerHeight) / this.parallaxWrapper.current.offsetHeight)) *
      (this.nbFrame * 0.1);
    this.setState({ scroll, perc });
  };

  getPopElementContent(ctn, isLarge) {
    return ctn ? (
      <Fragment>
        {ctn.icon && (
          <div className={`picto ${isLarge && 'picto-height'}`}>
            <img alt="" src={ctn.icon} />
          </div>
        )}
        {ctn.text && <p>{parse(ctn.text)}</p>}
        {ctn.hasSeparator && <hr />}
      </Fragment>
    ) : null;
  }

  getFirstPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          percStart={0}
          percStop={0.1}
          scroll={this.state.scroll}
          image="/assets/images/product-design/can-00.png"
          backTitle={parse(this.props.phases[0].backTitle)}
          className="silver"
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.01}
          percStop={0.07}
          className="medium"
          scroll={this.state.scroll}
          content={
            <Fragment>
              {this.getPopElementContent(this.props.phases[0].popElements[0])}
              {this.getPopElementContent(this.props.phases[0].popElements[1])}
            </Fragment>
          }
        />
      </Fragment>
    );
  }

  getColorName() {
    if (this.state.perc < 0.175) {
      return 'Bronze is for our low-tier products';
    } else if (this.state.perc < 0.275) {
      return 'Silver is for our mid-tier products';
    } else return 'Platinum gold is for our high-tier products';
  }

  getColorClass() {
    if (this.state.perc < 0.175) {
      return 'bronze';
    } else if (this.state.perc < 0.275) {
      return 'silver';
    } else return 'gold';
  }

  getSecondPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.1}
          percStop={0.2}
          image="/assets/images/product-design/can-03.png"
          backTitle={parse(this.props.phases[1].backTitle)}
          className="bronze"
        />
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.2}
          percStop={0.3}
          image="/assets/images/product-design/can-01.png"
          backTitle={parse(this.props.phases[1].backTitle)}
          className="silver"
        />
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.3}
          percStop={0.4}
          image="/assets/images/product-design/can-02.png"
          backTitle={parse(this.props.phases[1].backTitle)}
          className="gold"
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.12}
          percStop={0.32}
          scroll={this.state.scroll}
          content={
            <Fragment>
              {this.getPopElementContent(this.props.phases[1].popElements[0])}
              <div className={`navigation ${this.getColorClass()}`}>
                <span>3 colors: </span>
                <ul>
                  <li />
                  <li />
                  <li />
                </ul>
                <div className="color-name">{this.getColorName()}</div>
              </div>
            </Fragment>
          }
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.36}
          percStop={0.38}
          className="large"
          scroll={this.state.scroll}
          content={
            <Fragment>
              {this.getPopElementContent(this.props.phases[1].popElements[1])}

              {this.getPopElementContent(this.props.phases[1].popElements[2])}

              {this.getPopElementContent(this.props.phases[1].popElements[3])}
            </Fragment>
          }
        />
      </Fragment>
    );
  }

  toggleFuturLabel = () => {
    if (this.state.toggleActive.length === 0) {
      this.setState({ toggleActive: 'active' });
    } else {
      this.setState({ toggleActive: '' });
    }
  };

  getThirdPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.4}
          percStop={0.45}
          image="/assets/images/product-design/can-02.png"
          backTitle={parse(this.props.phases[2].backTitle)}
          isOverflow={true}
          extraContent={
            <div className="label-images">
              <img alt="" src="/assets/images/product-design/can-current-label.png" />
            </div>
          }
          className="gold"
        />
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.45}
          percStop={0.5}
          image="/assets/images/product-design/can-02.png"
          backTitle={parse(this.props.phases[2].backTitle)}
          isOverflow={true}
          extraContent={
            <div className="label-images">
              <img alt="" src="/assets/images/product-design/can-futur-label.png" />
            </div>
          }
          className="gold"
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.42}
          percStop={0.48}
          scroll={this.state.scroll}
          content={
            <Fragment>
              {this.getPopElementContent(this.props.phases[2].popElements[0])}
              <div className="toggle-btn">
                <button className={this.state.perc > 0.42 ? 'active' : ''}>
                  <div className="handle" />
                  <div className="label">
                    <div>{this.state.perc > 0.42 ? 'Futur Label' : 'Current Label'} </div>
                  </div>
                </button>
              </div>
            </Fragment>
          }
        />
      </Fragment>
    );
  }

  getFourthPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.5}
          percStop={0.7}
          image="/assets/images/product-design/can-02.png"
          backTitle={parse(this.props.phases[3].backTitle)}
          extraContent={
            <Fragment>
              <ProductDesignFreeElement
                perc={this.state.perc}
                percStart={0.56}
                percStop={0.65}
                style={{
                  top: '-140px',
                  left: '-80px',
                  width: '270px'
                }}
                content={
                  <Fragment>
                    <img alt="" src="/assets/images/product-design/zoom-cap.png" />
                  </Fragment>
                }
              />

              <ProductDesignFreeElement
                perc={this.state.perc}
                percStart={0.65}
                percStop={0.79}
                style={{
                  top: '-50px',
                  left: '140px',
                  width: '270px'
                }}
                content={
                  <Fragment>
                    <img alt="" src="/assets/images/product-design/zoom-handle.png" />
                  </Fragment>
                }
              />
            </Fragment>
          }
          className="gold"
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.52}
          percStop={0.56}
          scroll={this.state.scroll}
          content={
            <Fragment>
              {this.getPopElementContent(this.props.phases[3].popElements[0], true)}

              {this.getPopElementContent(this.props.phases[3].popElements[1], true)}
            </Fragment>
          }
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.60}
          percStop={0.63}
          scroll={this.state.scroll}
          content={
            <Fragment>
              {this.getPopElementContent(this.props.phases[3].popElements[2], true)}
              {this.getPopElementContent(this.props.phases[3].popElements[3], true)}
            </Fragment>}
        />

        <ProductDesignPopElement
          perc={this.state.perc}
          className="small"
          percStart={0.65}
          percStop={0.67}
          scroll={this.state.scroll}
          content={
            <Fragment>
              {this.getPopElementContent(this.props.phases[3].popElements[4])}
            </Fragment>}
        />
      </Fragment>
    );
  }

  getFifthPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.7}
          percStop={1.0}
          image="/assets/images/product-design/supply-chain.png"
          backTitle={parse(this.props.phases[4].backTitle)}
          className="gold"
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          className="large"
          percStart={0.73}
          percStop={1.0}
          scroll={this.state.scroll}
          content={
            <Fragment>
              {this.getPopElementContent(this.props.phases[4].popElements[0])}

              {this.getPopElementContent(this.props.phases[4].popElements[1])}

              {this.getPopElementContent(this.props.phases[4].popElements[2])}
              <BasicButton
                onClick={() => {
                  goTo(this.props.button.url, this.props.history);
                }}
                label={
                  <Fragment>
                    <Pdf /> {this.props.button.label}
                  </Fragment>
                }
              />
            </Fragment>
          }
        />
      </Fragment>
    );
  }

  getScroll(scroll) {
    let s = 0;
    if (!this.parallaxWrapper.current) return;
    if (scroll < 0) {
      s = -scroll;
    }
    const scrollBottom = scroll + window.innerHeight - this.parallaxWrapper.current.offsetHeight;
    if (scrollBottom > 0) {
      s = -scrollBottom;
    }
    return {
      transform: `translateY(${s}px)`
    };
  }

  getChapterNB() {
    if (this.state.perc < 0.1) {
      return '01';
    } else if (this.state.perc < 0.4) {
      return '02';
    } else if (this.state.perc < 0.5) {
      return '03';
    } else if (this.state.perc < 0.7) {
      return '04';
    } else {
      return '05';
    }
  }

  render() {
    return (
      <section ref={this.main} className="ProductDesignSection">
        <div className="content content-title" ref={this.topContent}>
          <h3 className="regular-title">{parse(this.props.title)}</h3>
          <h4 className="regular-subtitle">{parse(this.props.subtitle)}</h4>
        </div>
        <a
          href="#materials"
          style={{ position: 'absolute', top: this.props.viewPortHeight * 5 * 2 + 'px' }}
          name="materials"
          id="materials"
        />
        <div ref={this.parallaxWrapper} className="parallax-wrapper" style={{ height: this.state.fullHeight + 'px' }}>
          <div style={{ ...this.getScroll(this.state.scroll) }} className="fixed-container">
            {this.getFirstPhase()}
            {this.getSecondPhase()}
            {this.getThirdPhase()}
            {this.getFourthPhase()}
            {this.getFifthPhase()}
            <section className="static-elements" style={{}}>
              <div className="content">
                <div className="static-wrapper">
                  <div className="counter">
                    <div className="current-frame">{this.getChapterNB()}</div>
                    <hr />
                    <div className="total-frame">05</div>
                  </div>
                  <div className="arrows">
                    <ArrowParallaxTop />
                    <ArrowParallaxBottom />
                  </div>
                </div>

                <div className="side-instruction scrollInfo">Scroll To explore</div>
              </div>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

ProductDesignSection.propTypes = checkProps({
  dispatch: PropTypes.func,
  viewPortWidth: PropTypes.number,
  viewPortHeight: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  phases: PropTypes.array,
  button: PropTypes.object
});

ProductDesignSection.defaultProps = {};

const mapStateToProps = state => {
  return {
    viewPortWidth: state.resizing.width,
    viewPortHeight: state.resizing.height
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ProductDesignSection)
);
