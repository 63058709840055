import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './style.scss';

import checkProps from '../../util/check-props';
import CarrouselBtn from '../SvgComponents/CarrouselBtn/CarrouselBtn';
import BigTitle from '../BigTitle';
import CarrouselItem from '../CarrouselItem';
import BeforeAfter from '../BeforeAfter';

class RegularCarrouselSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      carouselContent: <Fragment />
    };
    this.indicator = React.createRef();
  }

  componentWillMount() {}

  componentDidMount() {
    this.setState(
      {
        carouselContent: (
          <Fragment>
            {this.props.carouselContent.map((c, i) => (
              <CarrouselItem
                key={i}
                buttonLabel={c.buttonLabel}
                buttonURL={c.buttonURL}
                slideNumber={c.slideNumber}
                subtitle={parse(c.subtitle)}
                text={parse(c.text)}
                title={parse(c.title)}
                isBeforeAfter={typeof c.visualCtn.beforeSRC === 'string'}
                visualCtn={
                  c.visualCtn.beforeSRC ? this.getBeforeAfter(c.visualCtn) : this.getRegularVisual(c.visualCtn)
                }
              />
            ))}
          </Fragment>
        )
      },
      () => {
        this.updateIndicator(this.state.currentSlide);
      }
    );
  }

  componentWillReceiveProps(nextProps) {}

  getBeforeAfter(visual) {
    return <BeforeAfter {...visual} />;
  }

  getRegularVisual(visual) {
    return <img alt="" src={visual} />;
  }

  next = () => {
    this.updateIndicator(this.state.currentSlide + 1);
    this.setState(state => ({
      currentSlide: state.currentSlide + 1
    }));
  };

  prev = () => {
    this.updateIndicator(this.state.currentSlide - 1);
    this.setState(state => ({
      currentSlide: state.currentSlide - 1
    }));
  };

  updateCurrentSlide = index => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.updateIndicator(index);
      this.setState({
        currentSlide: index
      });
    }
  };

  updateIndicator(index) {
    if (index < 0 || index >= this.indicator.current.childNodes.length) return;
    Array.from(this.indicator.current.childNodes).forEach(c => {
      c.classList.remove('active');
    });
    this.indicator.current.childNodes[index].classList.add('active');
  }

  getcarouselContent(carouselContent) {
    if (!carouselContent.props.children) return;
    return carouselContent.props.children.map((c, i) => {
      return c;
    });
  }

  getCarrouselIndicators(carouselContent) {
    if (!carouselContent.props.children) return;
    return carouselContent.props.children.map((c, i) => {
      return <li key={i} />;
    });
  }

  getCarrouselNumbers(carouselContent) {
    if (!carouselContent.props.children) return;
    return carouselContent.props.children.map((c, i) => {
      const pos = (i - this.state.currentSlide) * this.props.viewPortWidth;

      return (
        <div key={i} style={{ left: pos + 'px' }}>
          <div>0{i + 1}</div>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="RegularCarrouselSection">
        <section className="big-title">
          <div className="content">
            <BigTitle title={this.props.title} subtitle={this.props.subtitle} />
          </div>
        </section>
        <div className="carrousel-ctn">
          <div className="all-numbers">{this.getCarrouselNumbers(this.state.carouselContent)}</div>
          <Carousel
            selectedItem={this.state.currentSlide}
            onChange={this.updateCurrentSlide}
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            swipeable={false}
            infiniteLoop={true}
          >
            {this.getcarouselContent(this.state.carouselContent)}
          </Carousel>
          <button className="prevNext prev" onClick={this.prev}>
            <CarrouselBtn />
          </button>
          <button className="prevNext next" onClick={this.next}>
            <CarrouselBtn />
          </button>
        </div>
        <ul ref={this.indicator} className="indicator">
          {this.getCarrouselIndicators(this.state.carouselContent)}
        </ul>
      </div>
    );
  }
}

RegularCarrouselSection.propTypes = checkProps({
  dispatch: PropTypes.func,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  carouselContent: PropTypes.array,
  viewPortWidth: PropTypes.number,
  viewPortHeight: PropTypes.number
});

RegularCarrouselSection.defaultProps = {
  title: '',
  subtitle: ''
};

const mapStateToProps = state => {
  return {
    viewPortWidth: state.resizing.width,
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(RegularCarrouselSection);
