import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';

export default class MapSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { firstActive: true };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  showFirstMap = () => {
    this.setState({ firstActive: true });
  };

  showSecondMap = () => {
    this.setState({ firstActive: false });
  };

  render() {
    return (
      <section className="MapSection">
        <div className="content">
          <div className="sub-content">
            <h3 className="regular-title">{this.props.title}</h3>
            <h4 className="regular-subtitle">{this.props.subtitle}</h4>
            <p>{this.props.description}</p>
            {/* <button onClick={this.showFirstMap} className={`left-button ${this.state.firstActive ? 'active' : ''}`}>
              q2 2019
            </button>
            <button onClick={this.showSecondMap} className={`right-button ${!this.state.firstActive ? 'active' : ''}`}>
              q3-q4 2019
            </button> */}
          </div>
          <div className="maps-wrapper">
            <img src={this.props.mapSRC} alt="" />
          </div>
          <div className="sub-content terms">
            Production planning for other geographical areas is still under construction
          </div>
        </div>
      </section>
    );
  }
}

MapSection.propTypes = checkProps({
  title: PropTypes.string,
  subtitle: PropTypes.node,
  description: PropTypes.node,
  mapSRC: PropTypes.node
});

MapSection.defaultProps = {};
