import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import './style.scss';

import checkProps from '../../util/check-props';
import PlusButton from '../SvgComponents/PlusButton/PlusButton';

class ZoomPackageSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { translateX: 0, translateY: 0, currentBtn: -1, imgWidth: 0, imgHeight: 0 };
    this.fullProduct = React.createRef();
    this.zoomProduct = React.createRef();
    this.zoomImage = React.createRef();
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate() {
    if (this.state.imgWidth > 0 && this.state.currentBtn === -1 && this.props.interestPoints.length > 0) {
      this.setState({ imgWidth: this.zoomProduct.current.width, imgHeight: this.zoomProduct.current.height }, () => {
        const p0 = this.props.interestPoints[0];
        const rect = this.fullProduct.current.getBoundingClientRect();
        const x = (p0.x / this.state.imgWidth) * rect.width;
        const y = (p0.y / this.state.imgHeight) * rect.height;

        this.clickInterestPoint(x, y, 0);
      });
    }
  }

  initProducts = () => {
    this.setState({ imgWidth: this.zoomProduct.current.width, imgHeight: this.zoomProduct.current.height });
  };

  clickInterestPoint = (x, y, i) => {
    const rect = this.fullProduct.current.getBoundingClientRect();
    const zoomImageRect = this.zoomImage.current.getBoundingClientRect();
    this.setState({
      translateX: (-x / rect.width) * this.state.imgWidth + zoomImageRect.width * 0.5,
      translateY: (-y / rect.height) * this.state.imgHeight + zoomImageRect.height * 0.5,
      currentBtn: i
    });
  };

  createButton(x, y, i) {
    return (
      <button
        key={i}
        className={`point-interest ${i === this.state.currentBtn ? 'active' : ''}`}
        onClick={() => {
          this.clickInterestPoint(x, y, i);
        }}
        style={{ transform: `translate(${x - 22}px, ${y - 22}px)` }}
      >
        <PlusButton />
      </button>
    );
  }

  createAllButtons() {
    if (!this.fullProduct.current) return;
    const rect = this.fullProduct.current.getBoundingClientRect();
    return this.props.interestPoints.map((p, i) => {
      const x = (p.x / this.state.imgWidth) * rect.width;
      const y = (p.y / this.state.imgHeight) * rect.height;
      return this.createButton(x, y, i);
    });
  }

  createAllContent() {
    return this.props.interestPoints.map((p, i) => {
      return (
        <div
          key={i}
          className="interest-content"
          style={{
            visibility: this.state.currentBtn === i ? 'visible' : 'hidden',
            opacity: this.state.currentBtn === i ? 1 : 0
          }}
        >
          <h4>{p.title}</h4>
          <div className="interest-text">{parse(p.content)}</div>
        </div>
      );
    });
  }

  render() {
    return (
      <section className="ZoomPackageSection">
        <div className="content">
          <h3 className="regular-title sub-content">{this.props.title}</h3>
          <h4 className="regular-subtitle sub-content">{this.props.subtitle}</h4>
          <div className="product">
            <div className="full-product-wrapper">
              <div className="full-product" ref={this.fullProduct}>
                <img alt="" src={this.props.productSRC} />
                {this.createAllButtons()}
              </div>
            </div>
            <div className="zoom-product">
              <div className="zoom-image" ref={this.zoomImage}>
                <img
                  alt=""
                  ref={this.zoomProduct}
                  style={{ transform: `translate(${this.state.translateX}px, ${this.state.translateY}px)` }}
                  src={this.props.productSRC}
                  onLoad={this.initProducts}
                />
              </div>
              <div className="description">{this.createAllContent()}</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ZoomPackageSection.propTypes = checkProps({
  dispatch: PropTypes.func,
  viewPortWidth: PropTypes.number,
  viewPortHeight: PropTypes.number,
  interestPoints: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  productSRC: PropTypes.string
});

ZoomPackageSection.defaultProps = {
  interestPoints: []
};

const mapStateToProps = state => {
  return {
    viewPortWidth: state.resizing.width,
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(ZoomPackageSection);
