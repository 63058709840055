import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';
import BeforeAfter from '../BeforeAfter';

export default class LogoBeforeAfterSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  renderBALogo(item, i) {
    return (
      <div className="ba-wrapper" key={i}>
        <div className="ba-paddings">
          <BeforeAfter beforeSRC={item.beforeSRC} afterSRC={item.afterSRC} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <section className="LogoBeforeAfterSection">
        <div className="content">
          <h3 className="regular-title sub-content">{this.props.title}</h3>
          <h4 className="regular-subtitle sub-content">{this.props.subtitle}</h4>
          <div className="before-afters">{this.props.logos.map((item, i) => this.renderBALogo(item, i))}</div>
        </div>
      </section>
    );
  }
}

LogoBeforeAfterSection.propTypes = checkProps({
  title: PropTypes.node,
  subtitle: PropTypes.node,
  logos: PropTypes.array
});

LogoBeforeAfterSection.defaultProps = {};
