import React from 'react';
import { withRouter } from 'react-router-dom';

class GoogleAnalytics extends React.Component {
  componentDidMount() {
    this.waitTO = -1;
    this.started = false;
    const history = this.props.history;
    history.listen(this.track);

    this.track(this.props.location);
  }

  track = location => {
    clearTimeout(this.waitTO);
    if (!window.ga) {
      this.waitTO = setTimeout(() => {
        this.track(location);
      }, 300);
      return;
    }
    if (!this.started) {
      window.ga('create', 'UA-137899950-1');
      this.started = true;
    }
    window.ga('set', 'page', location.pathname + location.search);
    window.ga('send', 'pageview');
  };

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
