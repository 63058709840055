import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import './style.scss';

import checkProps from '../../util/check-props';
import BigTitle from '../BigTitle';

export default class PictoSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  getAllPicto() {
    return this.props.pictoList.map((p, i) => {
      return (
        <li key={i} style={{ width: `${Math.floor((1 / this.props.pictoList.length) * 100)}%` }}>
          <img src={p.img} alt="pictos" />
          <h4>{parse(p.title)}</h4>
          <span>{parse(p.label)}</span>
        </li>
      );
    });
  }

  render() {
    return (
      <section className={`PictoSection ${this.props.redStyle === 'true' ? 'redStyle' : ''}`}>
        <div className="content">
          {this.props.title ? (
            <Fragment>
              <BigTitle title={this.props.title} subtitle={this.props.subtitle} />
              <p>{parse(this.props.intro)}</p>
            </Fragment>
          ) : null}
          {this.props.pictoList.length > 0 ? <ul>{this.getAllPicto()}</ul> : null}
        </div>
      </section>
    );
  }
}

PictoSection.propTypes = checkProps({
  redStyle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  intro: PropTypes.node,
  pictoList: PropTypes.array
});

PictoSection.defaultProps = {
  redStyle: false,
  pictoList: []
};
