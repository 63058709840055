import React from 'react';

const ArrowLeft = props => (
  <svg viewBox="0 0 10 11" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>{`A8670022-074A-4056-BD73-3346FB4B895F`}</title>
    <path d="M9.018 0v10.86L0 5.428z" fill="#DF0C36" fillRule="evenodd" />
  </svg>
);

export default ArrowLeft;
