import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';

const smoothstep = require('smoothstep');

export default class ProductDesignFrame extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  getTransform(mh) {
    const t = `scaleY(${mh})`;
    return {
      transform: t
    };
  }

  getInvTransform(mh) {
    const t = `scaleY(${1 / mh})`;
    return {
      transform: t
    };
  }

  render() {
    let mh =
      smoothstep(this.props.percStart - 0.05, this.props.percStart, this.props.perc) *
      smoothstep(this.props.percStop, this.props.percStop - 0.05, this.props.perc);
    const display = mh === 0 ? 'none' : 'block';
    const to = this.props.perc > this.props.percStart ? '100%' : '0';
    return (
      <section className={'ProductDesignFrame ' + this.props.className} style={{ display }}>
        <div className="full-frame" style={{ ...this.getTransform(mh), transformOrigin: `50% ${to}` }}>
          <div className="full-frame-invert" style={{ ...this.getInvTransform(mh), transformOrigin: `50% ${to}` }}>
            <div className="content">
              <h2>{this.props.backTitle}</h2>
              <div className="bottle-wrapper" style={{ overflow: `${this.props.isOverflow ? 'hidden' : 'visible'}` }}>
                <div className="extra-content">{this.props.extraContent}</div>
                <img alt="" src={this.props.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ProductDesignFrame.propTypes = checkProps({
  percStart: PropTypes.number,
  percStop: PropTypes.number,
  perc: PropTypes.number,
  scroll: PropTypes.number,
  isOverflow: PropTypes.bool,
  className: PropTypes.string,
  backTitle: PropTypes.node,
  extraContent: PropTypes.node,
  image: PropTypes.string
});

ProductDesignFrame.defaultProps = {
  perc: 0,
  scroll: 0,
  percStart: 0,
  isOverflow: false,
  percStop: 1
};
