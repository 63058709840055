import React from 'react';

const Play = props => (
  <svg viewBox="0 0 135 72" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>{`E9971AD1-C7EB-4EB7-8377-121FC5EE7378`}</title>
    <defs>
      <path id="a" d="M0 .5h67.66V72H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(67 -.5)">
        <path
          d="M31.91.5C17.983.5 5.898 8.51 0 20.16c.596.326 1.224.697 1.732 1.005C7.282 10.109 18.72 2.5 31.91 2.5c18.61 0 33.75 15.14 33.75 33.75C65.66 54.859 50.52 70 31.91 70 19.38 70 8.43 63.13 2.608 52.964l-1.775.925C6.99 64.693 18.61 71.999 31.91 71.999c19.712 0 35.75-16.037 35.75-35.75C67.66 16.539 51.622.5 31.91.5"
          fill="#DE0D36"
        />
      </g>
      <path fill="#FEFEFE" d="M98.163 32.542v6.417l5.333-3.21z" />
      <text fill="#FFF" fontFamily="ArialMT, Arial" fontSize={11} letterSpacing={0.8} transform="translate(-1 -1)">
        <tspan x={0.8} y={42}>
          {`PLAY VIDEO`}
        </tspan>
      </text>
    </g>
  </svg>
);

export default Play;
