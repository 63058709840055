import React from 'react';

const Book = props => (
  <svg viewBox="0 0 22 18" {...props}>
    <title>{`602BAFFB-023C-4A90-9F25-BF7102806BB5`}</title>
    <g fill="#D0021B" fillRule="evenodd">
      <path d="M8.652 4.761c-.611-.085-1.22-.17-1.831-.259-.975-.137-1.95-.275-2.924-.41l-.678-.096c-.154-.022-.286-.025-.424.055a.566.566 0 0 0-.253.33c-.058.259.08.633.386.677.61.086 1.22.171 1.83.26.975.137 1.95.275 2.925.41.225.033.451.063.677.096.154.022.286.025.424-.055a.566.566 0 0 0 .253-.33c.058-.26-.08-.634-.385-.678M8.652 8.453c-.611-.085-1.22-.17-1.831-.258-.975-.138-1.95-.276-2.924-.41-.226-.034-.452-.064-.678-.097-.154-.022-.286-.025-.424.055a.566.566 0 0 0-.253.33c-.058.26.08.634.386.678a1065.867 1065.867 0 0 0 4.755.67c.225.032.451.062.677.095.154.022.286.025.424-.055a.566.566 0 0 0 .253-.33c.058-.259-.08-.633-.385-.678M8.652 12.146c-.611-.086-1.22-.17-1.831-.259-.975-.138-1.95-.275-2.924-.41l-.678-.097c-.154-.022-.286-.024-.424.055a.566.566 0 0 0-.253.33c-.058.262.08.637.386.678.61.086 1.22.17 1.83.259.975.138 1.95.275 2.925.41l.677.097c.154.022.286.024.424-.055a.566.566 0 0 0 .253-.33c.058-.26-.08-.634-.385-.678" />
      <path d="M17.898 15.252l-3.018.38-2.599.327c-.247.03-.494.062-.74.093V3.718c0-.447.013-.894.01-1.341l.48-.06 2.483-.315 3.018-.38 2.599-.327c.245-.03.493-.055.74-.084v12.325c0 .446-.013.894-.01 1.341l-.48.06-2.483.315m-10.433.424l-3.018-.38-2.599-.328c-.247-.03-.494-.067-.74-.1V2.401c0-.398.01-.798.01-1.198l.48.061 2.483.314 3.018.38 2.6.328c.246.03.493.067.74.1V14.852c0 .4-.006.8-.006 1.2l-.484-.062-2.484-.314M21.973.606V.581c0-.27-.253-.589-.55-.55l-1.041.132-2.484.314-3.018.38-2.599.327c-.424.053-.848.108-1.272.16-.006 0-.011.003-.02.003l-1.04-.132L7.465.9 4.447.52 1.848.193C1.427.141 1 .063.576.033L.556.031C.263-.008.007.314.007.58v12.955c0 .598-.014 1.198 0 1.796v.024c0 .325.253.512.55.551l1.042.132 2.483.314 3.018.38 2.6.328c.371.046.747.111 1.122.147.053.016.11.023.169.015a.39.39 0 0 0 .186-.023l.854-.109 2.484-.314 3.018-.38c.867-.11 1.732-.217 2.599-.328.424-.052.848-.107 1.272-.16l.02-.002c.294-.039.55-.223.55-.55V2.4c0-.597.014-1.197 0-1.795" />
      <path d="M18.76 3.996c-.611.085-1.22.17-1.831.258-.975.138-1.95.276-2.924.41-.226.034-.452.064-.678.097-.286.041-.468.419-.385.677.102.314.372.427.677.386.611-.085 1.22-.17 1.831-.259.975-.138 1.95-.275 2.924-.41.226-.033.452-.063.678-.097.286-.04.468-.418.385-.677-.102-.314-.372-.43-.677-.385M18.76 7.688c-.611.085-1.22.17-1.831.259-.975.138-1.95.275-2.924.41l-.678.096c-.286.042-.468.419-.385.678.102.314.372.427.677.385a1065.867 1065.867 0 0 1 4.755-.67c.226-.032.452-.062.678-.095.286-.041.468-.419.385-.677-.102-.314-.372-.427-.677-.386M18.76 11.383c-.611.086-1.22.17-1.831.259-.975.138-1.95.275-2.924.41l-.678.097c-.286.04-.468.418-.385.677.102.314.372.427.677.385.611-.085 1.22-.17 1.831-.258.975-.138 1.95-.276 2.924-.41.226-.034.452-.064.678-.097.286-.041.468-.419.385-.677-.102-.317-.372-.43-.677-.386" />
    </g>
  </svg>
);

export default Book;
