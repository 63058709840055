import React from 'react';

const Worldcrossed = props => (
  <svg viewBox="0 0 29 27" {...props}>
    <title>{`FE6E4ABB-845A-47AE-99BD-CC5F2D4C8BC1`}</title>
    <g fill="#FFF" fillRule="nonzero">
      <path d="M24.844 4.809l3.034-2.885a.957.957 0 0 0 0-1.415 1.08 1.08 0 0 0-1.487 0L.393 25.233a.957.957 0 0 0 0 1.414c.208.198.476.283.744.283.267 0 .535-.085.743-.283l3.034-2.885c2.47 2.036 5.711 3.253 9.221 3.253 7.794 0 14.13-6.026 14.13-13.437 0-3.338-1.31-6.421-3.421-8.77zm-.149 3.281h-3.331l1.993-1.867a13.59 13.59 0 0 1 1.338 1.867zm-5.503 5.488c0 1.245-.089 2.405-.238 3.48h-6.99l7.02-6.676c.119.99.208 2.065.208 3.196zm-12.79 8.77l1.665-1.585c.446 1.33 1.012 2.518 1.666 3.451a12.608 12.608 0 0 1-3.331-1.867zm7.733 2.687c-1.665 0-3.45-2.263-4.372-5.884l.119-.113h8.686c-.952 3.705-2.767 5.997-4.433 5.997zm4.403-.82c.922-1.359 1.666-3.112 2.142-5.177h4.015c-1.338 2.347-3.51 4.186-6.157 5.176zm2.528-7.158c.149-1.103.238-2.263.238-3.479 0-1.216-.089-2.376-.238-3.48h4.551c.357 1.104.566 2.264.566 3.48 0 1.216-.209 2.376-.566 3.48h-4.55zM3.992 19.717c-.149-.227-.297-.425-.416-.651h1.1l2.082-1.98H2.683a11.248 11.248 0 0 1-.565-3.48c0-1.216.208-2.376.565-3.479h4.551a26.202 26.202 0 0 0-.06 6.591l1.934-1.839c-.03-.452-.03-.877-.03-1.3 0-1.246.09-2.405.239-3.48h4.759l2.082-1.98H9.733c.922-3.678 2.737-5.97 4.402-5.97 1.399 0 2.886 1.585 3.867 4.244l1.607-1.528a13.386 13.386 0 0 0-1.071-1.923c.714.255 1.398.594 2.052.99l1.517-1.443A14.72 14.72 0 0 0 14.135.141C6.342.141.006 6.167.006 13.578c0 2.8.922 5.403 2.47 7.581l1.516-1.442zM9.733 2.942C8.811 4.3 8.067 6.054 7.591 8.119H3.576c1.338-2.348 3.51-4.187 6.157-5.177z" />
    </g>
  </svg>
);

export default Worldcrossed;
