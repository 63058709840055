export const API_URL = process.env.REACT_APP_API_URL; //'https://api.cli-total-pwa.tilvalhall.fr/'; process.env.REACT_APP_API_URL;

export const SITECONFIG = 'site_config.json';

export const JSONTOPREFETCH = [
  SITECONFIG,
  'products/total/classic.json',
  'products/elf-1/evolution.json',
  'products/total.json',
  'products/total-hi-perf/moto-2t.json',
  'products/total-rubia/optima.json',
  'products/total/motor-oil.json',
  'products/elf-1/sporti.json',
  '',
  'about.json',
  'logistics.json',
  'products/total-quartz.json',
  'products/total-hi-perf/moto-4t.json',
  'products/rubia/tir.json',
  'products/total/tractagri.json',
  'products/elf-1/elf-moto.json',
  'artwork.json',
  'products/total-hi-perf.json',
  'products/total-hi-perf/scooter-2t.json',
  'products/rubia/fleet.json',
  'products/total/dynatrans.json',
  'products/elf-1/tranself.json',
  'products/total-rubia.json',
  'products/total-hi-perf/scooter-4t.json',
  'products/rubia/gas.json',
  'products/total/multagri.json',
  'products/elf-1/gearelf.json',
  'products/elf-1/elfmatic.json',
  'products/elf-1.json',
  'products/rubia/s.json',
  'products/total/prosylva.json',
  'products/rubia/works.json',
  'products/total/others.json',
  'products/rubia/st.json',
  'products/total/caprano.json',
  'products/rubia/g.json',
  'products/total/neptuna.json',
  'products/rubia/sx.json',
  'products/total/traxium.json',
  'faq.json',
  'products/rubia/mt.json',
  'products/total/transtec.json',
  'products/total/fluidmatic.json',
  'contact.json',
  'products/total/coolants.json',
  'media.json',
  'products/total/hydraulic-and-breake-fluids.json',
  'elf-changes.json',
  'products/total/star-concept.json',
  'sustainability.json',
  'terms-of-use.json',
  'products/total/dynatrans-construction.json',
  'cookies-policy.json',
  'products/total/others-construction.json',
  'oem-changes.json'
];

export const staticAssets = [
  '/assets/images/product-design/zoom-cap.png',
  '/assets/images/product-design/zoom-handle.png',
  '/assets/images/product-design/can-00.png',
  '/assets/images/product-design/can-01.png',
  '/assets/images/product-design/can-02.png',
  '/assets/images/product-design/can-03.png',
  '/assets/images/product-design/can-current-label.png',
  '/assets/images/product-design/can-futur-label.png',
  '/assets/images/product-design/supply-chain.png',
  '/assets/images/product-design/squares-elf.png',
  '/assets/images/product-design/elf-label.png',
  '/assets/images/product-design/caps-group.png',
  '/assets/images/product-design/cap.png',
  '/assets/images/product-design/elf-bulb.png',
  '/assets/images/product-design/zoom-cap-elf.png',
  '/assets/images/product-design/can-futur-label-elf.png',
  '/assets/images/product-design/can-current-label-elf.png',
  '/assets/images/product-design/elf-jerican.png',
  '/assets/images/product-design/can-elf.png',
  '/assets/images/product-design/elf-handle.png',
  '/assets/images/common/logo-total-pure.png',
  '/assets/images/common/red-bg.jpg',
  '/assets/images/common/image-pwa.jpg',
  '/assets/placeholders/images/concept.png',
  '/assets/placeholders/images/product-can.png',
  '/assets/placeholders/images/description-can.png',
  ''
];

export const CACHE_NAME = 'total-pure-app-v1';
