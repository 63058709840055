import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import parse from 'html-react-parser';

import checkProps from '../../util/check-props';

export default class NumberTitle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className="NumberTitle">
        <div className="number" style={{ ...this.props.numberStyle }}>
          {parse(this.props.number)}
        </div>
        <div className="title">{parse(this.props.title)}</div>
      </div>
    );
  }
}

NumberTitle.propTypes = checkProps({
  number: PropTypes.string,
  title: PropTypes.string,
  numberStyle: PropTypes.object
});

NumberTitle.defaultProps = {
  numberStyle: {}
};
