import React from 'react';

const CanBrown = props => (
  <svg viewBox="0 0 56 56" {...props}>
    <title>{`5F1AF9A8-E59C-4407-B23D-7E24456A7B84`}</title>
    <g fill="none" fillRule="evenodd">
      <circle fill="#7D6A5F" cx={27.611} cy={27.611} r={27.611} />
      <path
        d="M27.844 30.023a.647.647 0 0 0-.56.26l-1.16 1.555c0 .027-.026.026-.026.053-.21.316-.316.686-.316 1.081 0 1.108.896 2.03 2.03 2.03.37 0 .66-.29.66-.659 0-.369-.29-.659-.66-.659a.709.709 0 0 1-.607-1.081l1.134-1.53a.666.666 0 0 0-.131-.923.674.674 0 0 0-.364-.127zm-.032-2.641l2.716 3.639v.026c.396.58.633 1.24.633 1.952a3.353 3.353 0 0 1-3.349 3.348A3.353 3.353 0 0 1 24.463 33c0-.686.211-1.346.58-1.9 0 0 .027-.026.027-.052l2.742-3.665zm-.026-1.609c-.264 0-.501.105-.686.316.027.027.027.027 0 .027l-3.059 4.114c-.026.026-.026.052-.053.052a4.65 4.65 0 0 0-.843 2.69 4.659 4.659 0 0 0 4.667 4.667 4.676 4.676 0 0 0 3.824-7.357l-.08-.078-3.058-4.088-.027-.027c-.158-.21-.421-.316-.685-.316zm-9.862-7.91h9.624l10.153 10.152v14.898H17.924v-25.05zm-.66-1.32c-.369 0-.659.29-.659.66v26.37c0 .369.29.659.66.659H38.36c.37 0 .66-.29.66-.66V27.752a.758.758 0 0 0-.185-.475L28.287 16.728a.759.759 0 0 0-.475-.184H17.264zm1.978-2.109h5.801v-1.846h-5.801v1.846zm-.66-3.164c-.368 0-.658.29-.658.66v2.504H17c-.37 0-.66.29-.66.66 0 .369.29.659.66.659h10.284c.369 0 .659-.29.659-.66 0-.369-.29-.659-.66-.659h-.922V11.93c0-.369-.29-.66-.66-.66h-7.12z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default CanBrown;
