import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.scss';
import parse from 'html-react-parser';

import checkProps from '../../util/check-props';
import BasicButton from '../BasicButton';
import { goTo } from '../../util/utilFn';

class FullWidthPictureSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <section className="FullWidthPictureSection">
        <div className="content">
          <h3 className="regular-title sub-content">{parse(this.props.title)}</h3>
          <h4 className="regular-subtitle sub-content">{parse(this.props.subtitle)}</h4>
          {this.props.ctaLabel && this.props.ctaLabel.length > 0 && (
            <div className="button-wrapper">
              <BasicButton
                onClick={() => {
                  goTo(this.props.ctaLink, this.props.history);
                }}
                label={this.props.ctaLabel}
              />
            </div>
          )}
          <img className="full-img" src={this.props.pictureSRC} alt="" />
        </div>
      </section>
    );
  }
}

FullWidthPictureSection.propTypes = checkProps({
  title: PropTypes.node,
  subtitle: PropTypes.node,
  pictureSRC: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string
});

FullWidthPictureSection.defaultProps = {};

export default withRouter(FullWidthPictureSection);
