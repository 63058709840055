import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';

const smoothstep = require('smoothstep');

export default class ProductDesignPopElement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  getScroll(scroll) {
    return {
      transform: `translateY(${scroll}px)`
    };
  }

  render() {
    let op =
      smoothstep(this.props.percStart - 0.05 * this.props.timeTransitionRatio, this.props.percStart, this.props.perc) *
      smoothstep(this.props.percStop, this.props.percStop - 0.01 * this.props.timeTransitionRatio, this.props.perc);
    if (isNaN(op)) op = 0;
    return (
      <section className={`ProductDesignPopElement ${this.props.className}`}>
        <div className="content">
          <div
            className="popElement"
            style={{
              right: this.props.right + 'px',
              visibility: op === 0 ? 'hidden' : 'visible',
              opacity: op,
              transform: `translateY(${(1 - op) * 20}px)`
            }}
          >
            {this.props.content}
          </div>
        </div>
      </section>
    );
  }
}

ProductDesignPopElement.propTypes = checkProps({
  content: PropTypes.node,
  className: PropTypes.string,
  percStart: PropTypes.number,
  timeTransitionRatio: PropTypes.number,
  percStop: PropTypes.number,
  perc: PropTypes.number,
  scroll: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number
});

ProductDesignPopElement.defaultProps = {
  perc: 0,
  scroll: 0,
  percStart: 0,
  percStop: 1,
  timeTransitionRatio: 1,
  top: 0,
  right: 0
};
