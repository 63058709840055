import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';
import TotalLogo from '../SvgComponents/TotalLogo/TotalLogo';

class Footer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { renderFooter: false };
  }

  componentDidMount() {
    this.checkPath();
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.checkPath();
    }
  }

  checkPath() {
    if (this.props.location.pathname === '/login' || this.props.location.pathname === '/password') {
      this.setState({ renderFooter: false });
    } else {
      this.setState({ renderFooter: true });
    }
  }

  getMenuItem(item, i) {
    return (
      <li key={i}>
        <NavLink activeClassName="active" exact={true} to={item.nav}>
          {item.label}
        </NavLink>
      </li>
    );
  }

  render() {
    if (!this.state.renderFooter) return null;
    return (
      <section className="Footer">
        <div className="content">
          <ul>
            <li>
              <TotalLogo />
            </li>

            {this.props.data.map((item, i) => this.getMenuItem(item, i))}
            <li>
              <span>©</span> Copyright 2019. PURE Project
              <ul className="footer-use">
                <li>
                  <NavLink activeClassName="active" exact={true} to="/terms-of-use">
                    General Terms and Conditions of Use and Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

Footer.propTypes = checkProps({
  data: PropTypes.array
});

Footer.defaultProps = {};

export default withRouter(Footer);
