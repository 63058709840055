import React from 'react';
import PropTypes from 'prop-types';
import { propTypes, withFormsy } from 'formsy-react';

import './style.scss';

class BasicCheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isChecked: false };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    nextProps.getValue();
  }

  changeValue = () => {
    const input = document.getElementById(this.props.name);
    input.checked = !input.checked;
    this.setState({ isChecked: input.checked });
    this.props.setValue(input.checked);
  };

  render() {
    return (
      <div className={`BasicCheckBox ${this.props.className}`}>
        <input
          name={this.props.name}
          id={this.props.name}
          onChange={this.changeValue}
          checked={this.state.isChecked}
          type={this.props.type}
          value={this.state.isChecked}
        />
        <label onClick={this.changeValue} htmlFor={this.props.id}>
          {this.props.label}
        </label>
      </div>
    );
  }
}

BasicCheckBox.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string
};

BasicCheckBox.defaultProps = {
  className: '',
  label: '',
  type: 'checkbox'
};

export default withFormsy(BasicCheckBox);
