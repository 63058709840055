import React from 'react';

const CarrouselBtn = props => (
  <svg viewBox="0 0 67 51" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>{`89E797C2-F7AA-40EB-A20A-11E05FDD5FA0`}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M25.522 51c9.81 0 18.323-5.657 22.478-13.886-.42-.23-.862-.492-1.22-.71-3.909 7.81-11.966 13.183-21.258 13.183-13.108 0-23.773-10.693-23.773-23.837 0-13.143 10.665-23.837 23.773-23.837 8.827 0 16.54 4.852 20.64 12.032l1.251-.653C43.076 5.66 34.892.5 25.523.5 11.636.5.34 11.828.34 25.75S11.637 51 25.522 51"
        fill="#D0021B"
      />
      <path fill="#666" d="M61.667 28.5v-6.417L67 25.292z" />
      <path d="M62.5 25.008H26.377" stroke="#666" strokeLinecap="square" />
    </g>
  </svg>
);

export default CarrouselBtn;
