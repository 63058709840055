import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';
import Formsy from 'formsy-react';
import BasicInput from '../../components/BasicInput';
import BasicCheckBox from '../../components/BasicCheckBox';
import BasicButton from '../../components/BasicButton';
import { login } from '../../store/modules/user';
import { checkAPIAccessible } from '../../util/utilFn';
import TotalLogo from '../../components/SvgComponents/TotalLogo/TotalLogo';

class Login extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { canSubmit: false, isLoggingIn: false };
    this.main = React.createRef();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    checkAPIAccessible().then(isAccessible => {
      if (!isAccessible) {
        window.setTimeout(() => {
          this.props.history.push('/');
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps) {
    if (this.props.isLoggingIn !== prevProps.isLoggingIn) {
      this.setState({ isLoggingIn: this.props.isLoggingIn });
      if (!this.props.isLoggingIn) {
        if (this.props.loggedIn) {
          this.props.history.push('/');
        }
      }
    }
  }

  submit = model => {
    this.props.login(model.username, model.password, model.remember || false);
  };

  disableButton = () => {
    this.setState({ canSubmit: false });
  };

  enableButton = () => {
    this.setState({ canSubmit: true });
  };
  checkHash() {}

  render() {
    return (
      <section ref={this.main} className="Login">
        <div className="content">
          <div className="form-popup">
            <TotalLogo />
            <h1>
              <span>Pure</span> Digital
              <br />
              Platform
            </h1>
            <Formsy
              ref="register-form"
              onValidSubmit={this.submit}
              onValid={this.enableButton}
              onInvalid={this.disableButton}
            >
              <div className="form-row">
                <BasicInput
                  label="Email"
                  name="username"
                  validationErrors={{
                    isDefaultRequiredValue: 'Email is required'
                  }}
                  required
                />
              </div>
              <div className="form-row">
                <BasicInput
                  label="Password"
                  name="password"
                  type="password"
                  validationErrors={{
                    isDefaultRequiredValue: 'Password is required'
                  }}
                  required
                />
              </div>
              <div className="row">
                <div className="column">
                  <BasicCheckBox label="Keep me connected" name="remember" />
                </div>
                <div className="column">
                  <Link to="/password">Forgot password?</Link>
                </div>
              </div>
              {this.props.error && this.props.error.length > 0 && <div className="row error">{this.props.error}</div>}
              <div className="row">
                <BasicButton label="Login" isSubmit={true} disabled={!this.state.canSubmit} />
              </div>
            </Formsy>
          </div>
          <div className="subscribe">
            <h4>Not subscribed yet?</h4>
            <a href="mailto:MS.LUB-PURE@TOTAL.COM">Contact the PURE project team for your registration</a>
          </div>
        </div>
        {this.props.isLoggingIn && (
          <div className="logging-in">
            <div className="content">Patientez</div>
          </div>
        )}
      </section>
    );
  }
}

Login.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  login: PropTypes.func,
  error: PropTypes.string,
  isLoggingIn: PropTypes.bool,
  loggedIn: PropTypes.bool
});

Login.defaultProps = {};

const mapStateToProps = state => {
  return {
    error: state.user.error,
    isLoggingIn: state.user.isLoggingIn,
    loggedIn: state.user.loggedIn
  };
};

const mapDispatchToProps = dispatch => ({
  login: (email, password, saveToken) => dispatch(login(email, password, saveToken))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
