import { START_LOGIN, LOGIN, LOGIN_FAIL } from '../keys';
import { API_URL } from '../../data/settings';

const defaultState = {
  isLoggingIn: false,
  loggedIn: false,
  token: null,
  error: ''
};

export default function userReducer(state = defaultState, action) {
  switch (action.type) {
    case START_LOGIN:
      window.sessionStorage.setItem('jwtToken', null);
      return { ...state, ...defaultState, isLoggingIn: true };
    case LOGIN:
      if (action.saveToken) {
        window.sessionStorage.setItem('jwtToken', action.token);
      }
      return { ...state, error: '', isLoggingIn: false, loggedIn: true, token: action.token };
    case LOGIN_FAIL:
      window.sessionStorage.setItem('jwtToken', null);
      return { ...state, ...defaultState, error: action.error };
    default:
      return state;
  }
}

function startLogin() {
  return { type: START_LOGIN };
}

function loginFail(response) {
  return { type: LOGIN_FAIL, error: response.message };
}

function loginSuccess(response, saveToken) {
  return { type: LOGIN, token: response.token, saveToken };
}

export function loginFromLocalStorage(token) {
  return { type: LOGIN, token };
}

export function tokenExpired() {
  return { type: LOGIN_FAIL, error: '' };
}

export function login(username, password, saveToken) {
  return dispatch => {
    dispatch(startLogin());
    return fetch(API_URL + 'login_check', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
      .then(response => {
        return response.json();
      })
      .then(response => {
        if (response.code && response.code !== 200) {
          return dispatch(loginFail(response));
        }
        return dispatch(loginSuccess(response, saveToken));
      });
  };
}
