import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import parse from 'html-react-parser';

import checkProps from '../../util/check-props';
import ArrowLeft from '../../components/SvgComponents/ArrowLeft/ArrowLeft';
import checkToken from '../../util/checkToken';
import { tokenExpired, loginFromLocalStorage } from '../../store/modules/user';
import componentBuilder from '../../util/componentBuilder';

class FAQ extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      catTop: 0,
      faq_content: [],
      components: (
        <div className="loading">
          <div className="text">Loading...</div>
        </div>
      )
    };
    this.qaFrame = React.createRef();
    this.main = React.createRef();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    window.addEventListener('scroll', this.checkCategoriesTop);
    checkToken('faq.json', this.props).then(
      data => {
        const comps = <Fragment>{componentBuilder(data.components)}</Fragment>;
        this.setState(
          {
            faq_content: data.components.filter(v => v.type === 'FaqSection')[0].content.faq_content,
            components: comps
          },
          () => {
            this.checkCategoriesTop();
          }
        );
      },
      () => {
        console.warn('token expired, return to login');
        this.props.tokenExpired();
        this.props.history.push('/login');
      }
    );
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkCategoriesTop);
  }

  checkCategoriesTop = () => {
    if (!this.qaFrame.current) return;
    const s = Math.max(this.qaFrame.current.getBoundingClientRect().top, 120);
    this.setState({ catTop: s + 'px' });
  };

  clickQuestion = event => {
    Array.from(document.querySelectorAll('.block-question')).forEach(b => b.classList.remove('open'));
    let parent = event.target;
    while (parent.tagName !== 'BUTTON') {
      parent = parent.parentNode;
    }
    parent.parentNode.classList.add('open');
  };

  addCategory(name, anchor) {
    return (
      <div className="category">
        <a href={`#${anchor}`} name={anchor} id={anchor}>
          {anchor}
        </a>
        <div className="title">
          <h3>{name}</h3>
          <div>
            Questions about <span>{name}</span>
          </div>
        </div>
        {Array.apply(null, Array(10)).map((q, i) => this.addQuestion(i))}
      </div>
    );
  }

  getAllQuestions() {
    return this.state.faq_content.map((item, i) => {
      return (
        <div key={i} className="category">
          <a
            href={`#${item.category.toLowerCase()}`}
            name={item.category.toLowerCase()}
            id={item.category.toLowerCase()}
          >
            {item.category.toLowerCase()}
          </a>
          <div className="title">
            <h3>{item.category}</h3>
            <div>
              Questions about <span>{item.category}</span>
            </div>
          </div>
          <Fragment>{item.questions.map((question, j) => this.addQuestion(question, j))}</Fragment>
        </div>
      );
    });
  }

  addQuestion(question, i) {
    return (
      <div className="block-question" key={i}>
        <button onClick={this.clickQuestion} className="question">
          <ArrowLeft />
          {question.question}
        </button>
        <div className="answer">{parse(question.answer)}</div>
      </div>
    );
  }
  checkHash() {}

  getAllCategories() {
    return this.state.faq_content.map((item, i) => {
      return (
        <li key={i}>
          <a href={`#${item.category.toLowerCase()}`}>
            {item.category}
            <sup>{item.count}</sup>
          </a>
        </li>
      );
    });
  }

  render() {
    return (
      <div ref={this.main} className="FAQ">
        {this.state.components}
        {this.state.faq_content.length > 0 && (
          <section className="faq-section">
            <div className="content">
              <ul className="all-categories" style={{ top: this.state.catTop }}>
                {this.getAllCategories()}
              </ul>
              <div ref={this.qaFrame} className="q-a">
                {this.getAllQuestions()}
              </div>
            </div>
          </section>
        )}
      </div>
    );
  }
}

FAQ.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  loginFromLocalStorage: PropTypes.func,
  tokenExpired: PropTypes.func,
  token: PropTypes.string
});

FAQ.defaultProps = {};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = dispatch => ({
  loginFromLocalStorage: token => dispatch(loginFromLocalStorage(token)),
  tokenExpired: () => dispatch(tokenExpired())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FAQ);
