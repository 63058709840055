import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import checkToken from '../../util/checkToken';
import { tokenExpired, loginFromLocalStorage } from '../../store/modules/user';
import componentBuilder from '../../util/componentBuilder';

import checkProps from '../../util/check-props';
class Elf extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      components: (
        <div className="loading">
          <div className="text">Loading...</div>
        </div>
      )
    };
    this.main = React.createRef();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    checkToken('oem-changes.json', this.props).then(
      data => {
        const comps = <Fragment>{componentBuilder(data.components, true)}</Fragment>;
        this.setState({
          components: comps
        });
      },
      () => {
        console.warn('token expired, return to login');
        this.props.tokenExpired();
        this.props.history.push('/login');
      }
    );
  }

  componentWillReceiveProps(nextProps) {}

  checkHash() {}

  render() {
    return (
      <div ref={this.main} className="Oem changes">
        {this.state.components}
      </div>
    );
  }
}

Elf.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  loginFromLocalStorage: PropTypes.func,
  tokenExpired: PropTypes.func,
  token: PropTypes.string
});

Elf.defaultProps = {};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = dispatch => ({
  loginFromLocalStorage: token => dispatch(loginFromLocalStorage(token)),
  tokenExpired: () => dispatch(tokenExpired())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Elf);
