import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';

import './style.scss';

import checkProps from '../../util/check-props';
import VideoPlayer from '../VideoPlayer';
import BasicButton from '../BasicButton';
import SkipIntro from '../SvgComponents/SkipIntro/SkipIntro';
import { goTo } from '../../util/utilFn';

class HeaderHero extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.backgroundWrapper = React.createRef();
    this.videoPlayer = React.createRef();
    this.section = React.createRef();
  }

  componentDidMount() {
    if (this.props.type === 'video') {
      window.addEventListener('scroll', this.checkScroll);
    }
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll);
  }

  checkScroll = () => {
    const rect = this.section.current.getBoundingClientRect();
    if (window.scrollY - rect.height > 0) {
      this.videoPlayer.current.stopVideo(true);
    }
  };

  getVideo() {
    return (
      <VideoPlayer
        ref={this.videoPlayer}
        videoSRC={this.props.src}
        isRegularVideo={true}
        loop={false}
        autoPlay={false}
        posterSRC={this.props.posterSrc}
        muted={false}
        onEnd={this.videoEnd}
        minHeight="100%"
      />
    );
  }

  getBackgroundPicture() {
    return <div className="background-image" style={{ backgroundImage: `url(${this.props.src})` }} />;
  }

  videoEnd = () => {
    this.videoPlayer.current.stopVideo(true);
    this.section.current.nextSibling.scrollIntoView({
      behavior: 'smooth',
      alignWithTop: true,
      block: 'start'
    });
  };

  skipIntro = event => {
    this.videoEnd();
  };

  render() {
    const maxHeight = this.props.sectionHeight ? { maxHeight: this.props.sectionHeight + 'vh' } : {};
    return (
      <section ref={this.section} className="HeaderHero" style={{ ...maxHeight }}>
        <div ref={this.backgroundWrapper} className="background">
          {this.props.type === 'video' ? this.getVideo() : this.getBackgroundPicture()}
        </div>
        {this.props.type !== 'video' ? (
          <div className="content">
            <div className="vertical-align-wrapper">
              {this.props.title ? <h1>{parse(this.props.title)}</h1> : null}
              {this.props.intro ? <p className="intro">{parse(this.props.intro)}</p> : null}
              {this.props.linkUrl && this.props.linkText ? (
                <BasicButton
                  label={this.props.linkText}
                  onClick={() => {
                    goTo(this.props.linkUrl, this.props.history);
                  }}
                />
              ) : null}
            </div>
            {this.props.scrollText ? <div className="side-instruction scrollInfo">{this.props.scrollText}</div> : null}
          </div>
        ) : null}
      </section>
    );
  }
  /* {this.props.type === 'video' ? (
          <button onClick={this.skipIntro} className="side-instruction skip-intro">
            <SkipIntro />
          </button>
        ) : null} */
}

HeaderHero.propTypes = checkProps({
  dispatch: PropTypes.func,
  type: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.node,
  intro: PropTypes.node,
  sectionHeight: PropTypes.string,
  scrollText: PropTypes.node,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  posterSrc: PropTypes.string
});

HeaderHero.defaultProps = {};

export default withRouter(HeaderHero);
