import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import parse from 'html-react-parser';

import checkProps from '../../util/check-props';

export default class WysiwygSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <section className="WysiwygSection">
        <div className="content">
          <h3 className="regular-title sub-content">{parse(this.props.title)}</h3>
          <h4 className="regular-subtitle sub-content">{parse(this.props.subtitle)}</h4>
          {parse(this.props.text)}
        </div>
      </section>
    );
  }
}

WysiwygSection.propTypes = checkProps({
  title: PropTypes.node,
  subtitle: PropTypes.node,
  text: PropTypes.node
});

WysiwygSection.defaultProps = {};
