import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import './style.scss';

import checkProps from '../../util/check-props';
import BasicButton from '../BasicButton';
import { goTo } from '../../util/utilFn';

class MultipleTabsSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { currentTab: -1, maxHeights: [] };
    this.tab = React.createRef();
  }

  componentWillMount() {
    this.setState({ maxHeights: this.props.tabs.map(() => 0) });
  }

  componentDidMount() {
    this.setState({ currentTab: 0 });
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentTab !== this.state.currentTab) {
      this.props.tabs.forEach((t, i) => {
        const prevMaxHeight = this.tab.current.childNodes[i].style.maxHeight;
        this.tab.current.childNodes[i].style.transition = 'none';
        this.tab.current.childNodes[i].style.maxHeight = 'none';
        const h = this.tab.current.childNodes[i].clientHeight + 'px';
        this.tab.current.childNodes[i].style.maxHeight = prevMaxHeight;
        this.tab.current.childNodes[i].style.transition = 'max-height 0.25s ease-in-out';
        setTimeout(() => {
          this.tab.current.childNodes[i].style.maxHeight = this.state.currentTab === i ? h : '0px';
        }, 10);
      });
    }
  }

  getAllTabs() {
    return this.props.tabs.map((t, i) => {
      return (
        <div key={i}>
          <h4>{parse(t.highlight)}</h4>
          <p>{parse(t.p)}</p>

          {t.extButton && t.extButton.label && (
            <BasicButton
              label={t.extButton.label}
              onClick={() => {
                goTo(t.extButton.url, this.props.history);
              }}
            />
          )}
        </div>
      );
    });
  }

  openTab(tabID) {
    this.setState({ currentTab: tabID });
  }

  getAllButtons() {
    return this.props.tabs.map((t, i) => {
      return (
        <li key={i}>
          <button
            className={this.state.currentTab === i ? 'active' : ''}
            onClick={() => {
              this.openTab(i);
            }}
          >
            <hr /> {t.button}
          </button>
        </li>
      );
    });
  }

  render() {
    return (
      <section className="MultipleTabsSection">
        <div className="content">
          <ul className="tabs-buttons">{this.getAllButtons()}</ul>
          <div className="tab" ref={this.tab}>
            {this.getAllTabs()}
          </div>
        </div>
      </section>
    );
  }
}

MultipleTabsSection.propTypes = checkProps({
  tabs: PropTypes.array
});

MultipleTabsSection.defaultProps = {
  tabs: []
};

export default withRouter(MultipleTabsSection);
