import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';
import ReactBackgroundVideoPlayer from 'react-background-video-player';

import checkProps from '../../util/check-props';
import Play from '../SvgComponents/Play/Play';
import Pause from '../SvgComponents/Pause/Pause';
import Fullscreen from '../SvgComponents/Fullscreen/Fullscreen';
import { noop } from '../../util/utilFn';
import screenfull from 'screenfull';

class VideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      videoWidth: 0,
      videoHeight: 0,
      isPlaying: false,
      isOver: false,
      isFullScreen: false,
      showControls: false
    };
    this.main = React.createRef();
    this.video = React.createRef();
    this.videoButton = React.createRef();
    this.overTO = -1;
  }

  componentDidMount() {
    this.setState({ isPlaying: this.props.autoPlay });
    document.addEventListener('fullscreenchange', this.fullScrenChange);
    this.videoButton.current.addEventListener('mousemove', this.showPauseButton);
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.viewPortWidth !== this.props.viewPortWidth ||
        prevProps.viewPortHeight !== this.props.viewPortHeight) &&
      this.props.videoSRC
    ) {
      this.setState({ videoWidth: this.main.current.offsetWidth, videoHeight: 500 });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.overTO);
    document.removeEventListener('fullscreenchange', this.fullScrenChange);
    this.videoButton.current.removeEventListener('mousemove', this.showPauseButton);
  }

  togglePlayVideo = () => {
    this.setState({ isPlaying: !this.state.isPlaying }, () => {
      if (this.state.isPlaying) this.video.current.play();
      else this.video.current.pause();
    });
  };

  showPauseButton = () => {
    clearTimeout(this.overTO);
    this.setState({ isOver: true });
    this.overTO = setTimeout(() => {
      this.setState({ isOver: false });
    }, 1000);
  };

  stopVideo = (preventCallBack = false) => {
    this.setState({ isPlaying: false }, () => {
      if (!preventCallBack) this.props.onEnd();
      clearTimeout(this.overTO);
      this.video.current.pause();
    });
  };

  toggleFullScreen = () => {
    this.setState({ isFullScreen: !this.state.isFullScreen }, () => {
      if (this.state.isFullScreen) {
        this.setFullScreen();
      } else {
        this.cancelFullScreen();
      }
    });
  };

  fullScrenChange = e => {
    let fs = true;
    if (!window.screenTop && !window.screenY) {
      fs = false;
    }
    this.setState({ isFullScreen: fs });
  };

  setFullScreen() {
    let element = this.main.current;
    if (screenfull.enabled) {
      screenfull.request(element);
    } else if (this.video.current.container) {
      element = this.video.current.container.childNodes[0];
      if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
      }
    }
  }

  cancelFullScreen() {
    if (screenfull.enabled) {
      screenfull.exit();
    } else if (this.video.current.container) {
      const element = this.video.current.container.childNodes[0];
      if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
      }
    }
  }

  getControls() {
    return (
      <div style={{ display: this.props.isRegularVideo && this.state.isPlaying ? 'none' : 'block' }}>
        <button ref={this.videoButton} className="inner vertical-align-wrapper" onClick={this.togglePlayVideo}>
          {!this.state.isPlaying ? <Play /> : <Pause />}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div
        ref={this.main}
        className={`VideoPlayer ${this.state.isPlaying ? 'playing' : ''} ${this.state.isOver ? 'over' : ''}`}
        style={{
          minHeight: this.props.isRegularVideo ? 'none' : this.props.minHeight,
          overflow: this.props.isRegularVideo ? 'hidden' : 'initial'
        }}
      >
        {this.props.isRegularVideo ? (
          <video
            src={this.props.videoSRC}
            poster={this.props.posterSRC}
            playsInline={true}
            controls={this.state.showControls}
            ref={this.video}
            loop={this.props.loop}
            muted={this.props.muted}
            autoPlay={this.props.autoPlay}
            width="100vw"
            height="90vh"
            onPlay={() => {
              this.setState({ isPlaying: true, showControls: true });
            }}
            // onEnd={this.stopVideo}
            style={{
              visibility: this.state.displayVideo,
              width: '100vw',
              height: '90vh',
              maxHeight: '90rem'
            }}
          />
        ) : (
          <ReactBackgroundVideoPlayer
            src={this.props.videoSRC}
            poster={this.props.posterSRC}
            playsInline={true}
            ref={this.video}
            loop={this.props.loop}
            muted={this.props.muted}
            autoPlay={this.props.autoPlay}
            containerWidth={this.state.videoWidth}
            containerHeight={this.state.videoHeight}
            onEnd={this.stopVideo}
            style={{ visibility: this.state.displayVideo }}
          />
        )}
        {this.props.showControls ? this.getControls() : null}
        {this.state.isPlaying ? (
          <button
            className="fullscreen side-instruction"
            onClick={this.toggleFullScreen}
            style={{ display: this.props.isRegularVideo ? 'none' : 'block' }}
          >
            {this.state.isFullScreen && screenfull.enabled ? 'Back to Site' : 'Full Screen'} <Fullscreen />
          </button>
        ) : null}
      </div>
    );
  }
}

VideoPlayer.propTypes = checkProps({
  onEnd: PropTypes.func,
  dispatch: PropTypes.func,
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  showControls: PropTypes.bool,
  isRegularVideo: PropTypes.bool,
  viewPortWidth: PropTypes.number,
  viewPortHeight: PropTypes.number,
  minHeight: PropTypes.string,
  posterSRC: PropTypes.string,
  videoSRC: PropTypes.string
});

VideoPlayer.defaultProps = {
  minHeight: '500px',
  onEnd: noop,
  showControls: true,
  autoPlay: false,
  isRegularVideo: false,
  muted: false,
  loop: false
};

const mapStateToProps = state => {
  return {
    viewPortWidth: state.resizing.width,
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null,
  null,
  { forwardRef: true }
)(VideoPlayer);
