import { API_URL } from '../data/settings';

export const noop = () => {};

export const wait = (delay = 0) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
};

export const convertToDate = timestamp => {
  const d = new Date(timestamp * 1000);
  let day = d.getDate();
  if (day.toString().length === 1) day = '0' + day;
  let m = d.getMonth() + 1;
  if (m.toString().length === 1) m = '0' + m;
  return day + '/' + m + '/' + d.getFullYear();
};

export const checkAPIAccessible = () => {
  return new Promise(resolve => {
    fetch(API_URL.substring(0, API_URL.length-1))
      .then(
        response => {
          if (response.status === 404) {
            return resolve(false);
          } else {
            return resolve(true);
          }
        },
        () => {
          return resolve(false);
        }
      )
      .catch(function() {
        return resolve(false);
      });
  });
};

export const detectIE = () => {
  var ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  // other browser
  return false;
};

export const goTo = (url, history) => {
  const pat = /^https?:\/\//i;
  if (pat.test(url)) {
    document.location.href = url;
  } else {
    history.push(url);
  }
};
