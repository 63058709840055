import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.scss';
import parse from 'html-react-parser';

import checkProps from '../../util/check-props';
import NumberTitle from '../NumberTitle';
import BasicButton from '../BasicButton';
import VideoPlayer from '../VideoPlayer';
import { goTo } from '../../util/utilFn';

class VisualTextSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    const isNotNumber = this.props.number === null || this.props.number === '';
    return (
      <section
        className={`VisualTextSection ${this.props.reversed && 'reverse'} ${this.props.halfWidth &&
          'half-width'} ${isNotNumber && 'no-padding'}`}
      >
        <div className="background" style={{ ...this.props.style }} />
        <div
          className={`background-image ${!this.props.video ? 'visual-only' : ''}  ${
            this.props.isProduct ? 'product' : ''
          }  ${this.props.video ? 'video' : ''}`}
          style={{ backgroundImage: `url(${!this.props.video ? this.props.visual : ''})` }}
        >
          {this.props.video ? (
            <div className="video-wrapper">
              <VideoPlayer posterSRC={this.props.visual} videoSRC={this.props.video} minHeight="100%" />
            </div>
          ) : null}
        </div>
        <div className="content">
          <div className="text">
            <NumberTitle number={this.props.number} numberStyle={this.props.numberStyle} title={this.props.title} />
            <h4>{parse(this.props.subtitle)}</h4>
            {parse(this.props.text)}
            {this.props.buttonLabel && this.props.buttonURL && (
              <BasicButton
                onClick={() => {
                  goTo(this.props.buttonURL, this.props.history);
                }}
                label={this.props.buttonLabel}
              />
            )}
          </div>
        </div>
      </section>
    );
  }
}

VisualTextSection.propTypes = checkProps({
  backgroundSection: PropTypes.string,
  reversed: PropTypes.bool,
  isProduct: PropTypes.bool,
  style: PropTypes.object,
  visual: PropTypes.string,
  halfWidth: PropTypes.bool,
  video: PropTypes.string,
  number: PropTypes.string,
  numberStyle: PropTypes.object,
  title: PropTypes.node,
  subtitle: PropTypes.node,
  text: PropTypes.node,
  buttonLabel: PropTypes.string,
  buttonURL: PropTypes.string
});

VisualTextSection.defaultProps = {
  backgroundSection: 'white',
  isProduct: false,
  number: null,
  style: {}
};

export default withRouter(VisualTextSection);
