import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink, withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import './style.scss';

import checkProps from '../../util/check-props';
import BasicButton from '../BasicButton';
import Worldcrossed from '../SvgComponents/Worldcrossed/Worldcrossed';
import { wait, checkAPIAccessible, detectIE } from '../../util/utilFn';
import { toggleDownloadApp } from '../../store/modules/downloadApp';

class MainMenu extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { renderMenu: false, showPopup: false, apiAccessible: true };
    this.mainMenu = React.createRef();
  }

  componentDidMount() {
    this.checkPath();
    checkAPIAccessible().then(isAccessible => {
      this.setState({ apiAccessible: isAccessible });
    });
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.checkPath();
    }
  }

  downloadApp = () => {
    this.setState({ showPopup: false }, () => {
      this.props.toggleDownloadApp(true);
    });
  };

  checkPath() {
    if (this.props.location.pathname === '/login' || this.props.location.pathname === '/password') {
      this.setState({ renderMenu: false });
    } else {
      this.setState({ renderMenu: true });
    }
  }

  openTabletMenu = e => {
    let parent = e.target;
    while (parent.tagName !== 'BUTTON') {
      parent = parent.parentNode;
    }
    parent.parentNode.parentNode.classList.toggle('active');
    document.body.classList.toggle('tablet-menu');
  };

  closeMenu = () => {
    document.body.classList.remove('tablet-menu');
    this.mainMenu.current.classList.remove('active');
  };

  closeSubMenu = () => {
    if (detectIE() !== false) return;
    document
      .querySelectorAll('.MainMenu .sub-menu, .MainMenu .half-square-wrapper, .MainMenu .menu-wrapper > .background')
      .forEach(item => {
        item.style = 'display: none';
      });
    wait(1000).then(() => {
      document
        .querySelectorAll('.MainMenu .sub-menu, .MainMenu .half-square-wrapper, .MainMenu .menu-wrapper > .background')
        .forEach(item => {
          item.style = '';
        });
    });
  };

  clickMenu = (e, parentTag = 'BUTTON') => {
    let parent = e.target;
    while (parent.tagName !== parentTag) {
      parent = parent.parentNode;
    }
    if (!parent.parentNode.parentNode.classList.contains('active')) {
      document.querySelectorAll('.MainMenu .tablet-menu .main-menu > li').forEach(c => c.classList.remove('active'));
    }

    parent.parentNode.parentNode.classList.toggle('active');
  };

  clickProductMenu = (e, isSingleButton) => {
    let parent = e.target;
    while (parent.tagName !== 'LI') {
      parent = parent.parentNode;
    }
    if (!parent.classList.contains('active')) {
      document
        .querySelectorAll('.MainMenu .tablet-menu .main-menu > li .products-wrapper .product-menu > li')
        .forEach(c => {
          c.classList.remove('active');
          c.style.height = 'auto';
        });
    }
    if (!isSingleButton) {
      parent.classList.toggle('active');
      const h = parent.querySelector('table').clientHeight + 90;
      parent.style.height = h + 'px';
    } else {
      this.closeMenu();
    }
  };

  closePopup = e => {
    this.setState({ showPopup: false });
  };

  openPopup = e => {
    checkAPIAccessible().then(isAccessible => {
      this.setState({ apiAccessible: isAccessible, showPopup: isAccessible });
    });
  };

  populateProducts(infos, isTablet) {
    const perc = (1 / infos.nbCols) * 100 + '%';
    const style = { width: perc };
    let biggetColumn = [];
    let maxItem = -1;
    infos.values.forEach((col, i) => {
      if (col.length > maxItem) {
        maxItem = col.length;
        biggetColumn = col;
      }
    });
    return (
      <table cellPadding="0" cellSpacing="0">
        <tbody>
          <tr>
            {infos.th.map((v, i) => (
              <td key={i} style={style}>
                {parse(v)}
              </td>
            ))}
          </tr>
          {biggetColumn.map((v, i) => {
            return (
              <tr key={i}>
                {infos.th.map((t, j) => (
                  <td key={j} style={style}>
                    {infos.values[j][i] && (
                      <Link to={infos.values[j][i].url} onClick={isTablet ? this.closeMenu : null}>
                        {parse(infos.values[j][i].label || '')}
                      </Link>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  buildProductsMenu(item, isTablet) {
    const productsData = item.products_data;
    return (
      <div className="products-wrapper">
        <ul className="product-menu">
          <li>
            <button
              onClick={e => {
                this.props.history.push(productsData[0].buttonUrl);
                if (isTablet) {
                  this.clickProductMenu(e, true);
                }
              }}
            >
              <img alt="" src={productsData[0].logo} />
            </button>
          </li>
          {productsData.map((item, i) => (
            <Fragment key={i}>
              {i === 0 ? null : (
                <li className={`${i === 3 ? 'total-special-menu' : ''} ${i === 4 ? 'elf' : ''}`}>
                  <button
                    onClick={e => {
                      if (isTablet) {
                        this.clickProductMenu(e);
                      } else {
                        if (!item.values || item.values.length === 0) {
                          this.props.history.push(item.buttonUrl);
                        }
                      }
                    }}
                  >
                    <img alt="" src={item.logo} />
                  </button>
                  {item.values && item.values.length > 0 ? (
                    <Fragment>
                      <div className="half-square-wrapper">
                        <div />
                      </div>
                      {this.populateProducts(item, isTablet)}
                    </Fragment>
                  ) : null}
                </li>
              )}
            </Fragment>
          ))}
        </ul>
      </div>
    );
  }

  buildSubMenuItem(item, isTablet) {
    return (
      <ul className="sub-menu">
        {item.sub_menu.map((subItem, i) => (
          <li key={i}>
            <Link to={subItem.hash} onClick={isTablet ? this.closeMenu : this.closeSubMenu}>
              {parse(subItem.label)}
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  buildMenuItem(item, isTablet, i) {
    return (
      <li key={i} className={item.type === 'products' ? 'product-li' : ''}>
        <div className="btn-container">
          {item.type !== 'products' ? <NavLink
            activeClassName="active"
            onClick={isTablet ? this.closeMenu : null}
            exact={item.type !== 'products'}
            to={item.nav}
          >
            {parse(item.label)}
          </NavLink> : <span onClick={isTablet ? (e) => { this.clickMenu(e, 'SPAN'); } : null}>{parse(item.label)}</span>}
          
          <button className="button-tablet" onClick={isTablet ? this.clickMenu : null}>
            <div className="arrow" />
          </button>
        </div>
        {item.sub_menu && (
          <div className="half-square-wrapper">
            <div />
          </div>
        )}
        {item.sub_menu && this.buildSubMenuItem(item, isTablet)}
        {item.type === 'products' && this.buildProductsMenu(item, isTablet)}
      </li>
    );
  }

  getMainMenu(isTablet) {
    return (
      <ul className={`main-menu ${isTablet ? 'tablet' : ''}`}>
        {this.props.data.map((item, i) => this.buildMenuItem(item, isTablet, i))}
      </ul>
    );
  }

  pwaPopup() {
    return (
      <div className="popup">
        <div className="background-popup" />
        <div className="popup-content">
          <div className="visual">
            <img alt="" src="/assets/images/common/image-pwa.jpg" />
          </div>
          <div className="text">
            <h2>Consult the website offline</h2>
            <p>In order to consult the website offline, please confirm the download.</p>
            <div className="buttons">
              <BasicButton onClick={this.downloadApp} label="download" type="red" />
              <BasicButton onClick={this.closePopup} label="Close" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.renderMenu) return null;
    const pwaDoNotNeedsUpdate =
      navigator.serviceWorker && navigator.serviceWorker.controller && !this.props.needsUpdate;
    return (
      <div className="MainMenu" ref={this.mainMenu}>
        <div className="menu-wrapper">
          <button onClick={this.openTabletMenu} className="tablet-menu-button">
            <div className="hamburger">
              <div />
              <div />
              <div />
            </div>
            <div className="close">
              <div />
              <div />
            </div>
          </button>
          <div className="logo">
            <img src="/assets/images/common/logo-total-pure.png" alt="total pure" />
          </div>
          <div className="background-wrapper">
            <div />
          </div>
          {this.getMainMenu()}
          {'serviceWorker' in navigator && (
            <div className="download-button">
              <BasicButton
                onClick={this.openPopup}
                disabled={!this.state.apiAccessible || pwaDoNotNeedsUpdate}
                label={
                  <Fragment>
                    <Worldcrossed />
                    {navigator.serviceWorker && navigator.serviceWorker.controller ? 'Update' : 'Download'} offline
                    version
                  </Fragment>
                }
              />
              <button
                className="logout"
                onClick={() => {
                  window.sessionStorage.setItem('jwtToken', null);
                  this.props.history.push('/login');
                }}
              >
                Log out
              </button>
            </div>
          )}
        </div>
        <div className="tablet-menu">{this.getMainMenu(true)}</div>
        {this.state.showPopup ? this.pwaPopup() : null}
      </div>
    );
  }
}

MainMenu.propTypes = checkProps({
  needsUpdate: PropTypes.bool,
  toggleDownloadApp: PropTypes.func,
  data: PropTypes.array
});

MainMenu.defaultProps = {};

const mapStateToProps = state => {
  return {
    needsUpdate: state.downloadApp.needsUpdate
  };
};

const mapDispatchToProps = dispatch => ({
  toggleDownloadApp: toggle => dispatch(toggleDownloadApp(toggle))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MainMenu)
);
