import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';
import ArrowLeft from '../SvgComponents/ArrowLeft/ArrowLeft';

class BeforeAfter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { sliderX: 50, startDrag: false, imageWidth: 0 };
    this.refImage = React.createRef();
    this.main = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.mouseMove);
    window.addEventListener('mouseup', this.stopDrag);
    this.main.current.addEventListener('touchmove', this.mouseMove);
    this.main.current.addEventListener('touchup', this.stopDrag);
    this.checkSize();
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.viewPortWidth !== this.props.viewPortWidth ||
      prevProps.viewPortHeight !== this.props.viewPortHeight ||
      this.state.imageWidth === 0
    ) {
      this.checkSize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.mouseMove);
    window.removeEventListener('mouseup', this.stopDrag);
    this.main.current.removeEventListener('touchmove', this.mouseMove);
    this.main.current.removeEventListener('touchup', this.stopDrag);
  }

  mouseMove = e => {
    if (this.state.startDrag) this.dragSlider(e);
  };

  checkSize = () => {
    this.setState({ imageWidth: this.refImage.current.getBoundingClientRect().width });
  };

  dragSlider = event => {
    const cX = event.changedTouches && event.changedTouches.length > 0 ? event.changedTouches[0].pageX : event.clientX;
    let p = Math.min(
      1,
      Math.max(0, (cX - this.refImage.current.getBoundingClientRect().left) / this.refImage.current.clientWidth)
    );
    p = Math.min(this.props.limitMax, Math.max(this.props.limitMin, Math.floor(p * 100)));
    this.setState({ sliderX: p });
  };

  startDrag = () => {
    this.setState({ startDrag: true });
  };

  stopDrag = () => {
    this.setState({ startDrag: false });
  };

  render() {
    return (
      <div ref={this.main} className={`BeforeAfter ${this.props.addClassName}`}>
        <div ref={this.refImage} className="before">
          {this.props.domContent ? (
            <div style={{ width: this.state.imageWidth + 'px' }}>
              <div className="before-oil">
                <div>{this.props.afterLabel}</div>
                <img src={this.props.beforeSRC} alt="" onLoad={this.checkSize} />
              </div>
            </div>
          ) : (
            <img
              alt=""
              onLoad={this.checkSize}
              style={{ width: this.state.imageWidth + 'px' }}
              src={this.props.beforeSRC}
            />
          )}
        </div>
        <div className="after" style={{ width: `${this.state.sliderX}%` }}>
          {this.props.domContent ? (
            <div style={{ width: this.state.imageWidth + 'px' }}>
              <div className="after-oil">
                <div>{this.props.beforeLabel}</div>
                <img src={this.props.afterSRC} alt="" onLoad={this.checkSize} />
              </div>
            </div>
          ) : (
            <img
              alt=""
              onLoad={this.checkSize}
              style={{ width: this.state.imageWidth + 'px' }}
              src={this.props.afterSRC}
            />
          )}
        </div>
        <div className="slider" style={{ left: `${this.state.sliderX}%` }}>
          <div className="border" />
          <button onMouseDown={this.startDrag} onTouchStart={this.startDrag}>
            <ArrowLeft />
            <ArrowLeft className="right" />
          </button>
        </div>
      </div>
    );
  }
}

BeforeAfter.propTypes = checkProps({
  dispatch: PropTypes.func,
  viewPortWidth: PropTypes.number,
  limitMin: PropTypes.number,
  limitMax: PropTypes.number,
  viewPortHeight: PropTypes.number,
  addClassName: PropTypes.string,
  beforeSRC: PropTypes.string,
  afterSRC: PropTypes.string,
  domContent: PropTypes.bool,
  beforeContent: PropTypes.node,
  afterContent: PropTypes.node,
  afterLabel: PropTypes.string,
  beforeLabel: PropTypes.string
});

BeforeAfter.defaultProps = {
  domContent: false,
  limitMin: 0,
  limitMax: 100,
  addClassName: ''
};

const mapStateToProps = state => {
  return {
    viewPortWidth: state.resizing.width,
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(BeforeAfter);
