import { API_URL } from '../data/settings';

export default (page, props) => {
  return new Promise((resolve, reject) => {
    const isServiceWorker = navigator.serviceWorker && navigator.serviceWorker.controller;
    let token = sessionStorage.getItem('jwtToken') || props.token;
    if (token === 'null') token = props.token;
    if (token !== null) {
      props.loginFromLocalStorage(token);
    } else if (props.token === null) {
      if (!isServiceWorker) {
        return reject();
      }
    } else {
      token = props.token;
    }
    const auth = isServiceWorker ? {} : { Authorization: `Bearer ${token}` };

    let finalURL = API_URL;
    if (page == '') {
      finalURL = finalURL.substring(0, finalURL.length - 1);
    }
    fetch(finalURL + page, {
      headers: {
        ...auth
      }
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.code && data.code !== 200) {
          reject();
        } else {
          resolve(data);
        }
      })
      .catch(function(error) {
        console.error('Looks like there was a problem: \n', error);
        reject();
      });
  });
};
