import React from 'react';

const Pdf = props => (
  <svg viewBox="0 0 16 21" {...props} preserveAspectRatio="xMidYMin meet">
    <title>{`3B4521BE-C337-427A-8497-DB6760B9F06B`}</title>
    <path
      d="M10.116 6.473a.698.698 0 0 1-.704-.692V0H.862A.855.855 0 0 0 0 .848v19.11c0 .467.388.847.866.847h14.268a.859.859 0 0 0 .866-.85V6.473h-5.884zm1.628 8.184c-.128.063-.32.126-.576.126a4.65 4.65 0 0 1-1.92-.443c-1.088.127-1.92.253-2.56.507-.064 0-.064 0-.128.063-.768 1.331-1.408 1.965-1.92 1.965-.128 0-.192 0-.256-.063l-.32-.19v-.064C4 16.431 4 16.368 4 16.241c.064-.317.448-.887 1.216-1.33.128-.064.32-.19.576-.318.192-.317.384-.697.64-1.14.32-.634.512-1.268.704-1.839-.256-.76-.384-1.204-.128-2.091.064-.254.256-.508.512-.508h.128c.128 0 .256.064.384.127.448.444.256 1.458 0 2.282v.063c.256.698.64 1.268 1.024 1.648.192.127.32.254.576.38.32 0 .576-.063.832-.063.768 0 1.28.127 1.472.444.064.127.064.254.064.38-.064.064-.128.254-.256.38zm-4.16-2.472c-.128.443-.384.95-.64 1.52-.128.254-.256.445-.384.698h.128c.832-.317 1.6-.507 2.112-.57-.128-.064-.192-.127-.256-.19-.32-.38-.704-.888-.96-1.458zm3.904 1.965c-.064-.064-.32-.254-1.216-.254h-.128v.063c.448.19.896.317 1.216.317h.192v-.063s-.064 0-.064-.063zM5.6 15.1c-.128.064-.256.127-.32.19-.448.38-.768.825-.832 1.015.384-.064.768-.444 1.152-1.205zm1.92-4.373c.064-.254.128-.38.128-.57v-.127c.064-.317.064-.57 0-.634v-.064l-.064-.063s0 .063-.064.063c-.128.38-.128.824 0 1.395zM16 5.548h-5.175a.468.468 0 0 1-.472-.463V0L16 5.548z"
      fill="#DF0C36"
      fillRule="nonzero"
    />
  </svg>
);

export default Pdf;
