import React from 'react';
import './style.scss';

import checkProps from '../../util/check-props';
import TotalLogo from '../SvgComponents/TotalLogo/TotalLogo';

export default class OrientationPortrait extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <section className="OrientationPortrait">
        <div className="content">
          <TotalLogo />
          <p>Please rotate your tablet in landscape to see this site.</p>
        </div>
      </section>
    );
  }
}

OrientationPortrait.propTypes = checkProps({});

OrientationPortrait.defaultProps = {};
