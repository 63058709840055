import React from 'react';

const Pause = props => (
  <svg id="Layer_2" data-name="Layer 2" viewBox="0 0 135 72" {...props}>
    <defs />
    <title>{`pause`}</title>
    <g id="Layer_1" data-name="Layer 1">
      <g id="Group-3">
        <path
          id="Fill-1"
          d="M98.91.26A35.77 35.77 0 0 0 67 19.92c.6.33 1.22.7 1.73 1a33.75 33.75 0 1 1 .88 31.8l-1.78.93A35.74 35.74 0 1 0 98.91.26"
          fill="#de0d36"
        />
      </g>
      <path className="cls-2" d="M96.16 32.48h1.77v7.57h-1.77zM99.72 32.48h1.77v7.57h-1.77z" fill="#FFFFFF" />
    </g>
    <text
      transform="translate(30.8 41)"
      style={{ isolation: 'isolate' }}
      fontSize={11}
      fill="#fff"
      fontFamily="ArialMT,Arial"
      letterSpacing={0}
      id="Play-Video"
    >
      {`P`}
      <tspan x={7.31} y={0} letterSpacing=".07em">
        {`AUSE`}
      </tspan>
    </text>
  </svg>
);

export default Pause;
