import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.scss';
import checkToken from '../../util/checkToken';
import { tokenExpired, loginFromLocalStorage } from '../../store/modules/user';
import componentBuilder from '../../util/componentBuilder';

import checkProps from '../../util/check-props';
class Products extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      components: (
        <div className="loading">
          <div className="text">Loading...</div>
        </div>
      )
    };
    this.main = React.createRef();
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    const path = this.props.history.location.pathname;
    let product = '';
    if (path.indexOf('/') > -1) {
      const p = path.split('/');
      if (p.length >= 3) {
        product = path.substring(1);
      } else {
        return this.props.history.push('/products/total-quartz');
      }
    } else {
      return this.props.history.push('/products/total-quartz');
    }
    checkToken(product + '.json', this.props).then(
      data => {
        const comps = <Fragment>{componentBuilder(data.components, true)}</Fragment>;
        this.setState({
          components: comps
        });
      },
      () => {
        console.warn('token expired, return to login');
        this.props.tokenExpired();
        this.props.history.push('/login');
      }
    );
  }

  componentWillReceiveProps(nextProps) {}

  checkHash() {}

  render() {
    return (
      <div ref={this.main} className="Products">
        {this.state.components}
      </div>
    );
  }
}

Products.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  loginFromLocalStorage: PropTypes.func,
  tokenExpired: PropTypes.func,
  token: PropTypes.string
});

Products.defaultProps = {};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = dispatch => ({
  loginFromLocalStorage: token => dispatch(loginFromLocalStorage(token)),
  tokenExpired: () => dispatch(tokenExpired())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Products)
);
