import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.scss';

import checkProps from '../../util/check-props';
import Book from '../SvgComponents/Book/Book';
import Pdf from '../SvgComponents/Pdf/Pdf';
import Download from '../SvgComponents/Download/Download';
import { goTo } from '../../util/utilFn';

class MediaSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  getTableLine(imgSRC, title, descr, readLink, downloadLink, isPDFIcon, i) {
    return (
      <tr key={i}>
        <td className="logo">
          <img src={imgSRC} alt="logo" />
        </td>
        <td className="description">
          <span>{title}</span>
          <span className="descr">{descr}</span>
        </td>
        <td className="buttons" align="right">
          {readLink ? (
            <button
              onClick={() => {
                goTo(readLink, this.props.history);
              }}
            >
              <Book /> Read Online
            </button>
          ) : null}
          {downloadLink ? (
            <button
              onClick={() => {
                goTo(downloadLink, this.props.history);
              }}
            >
              {isPDFIcon ? <Pdf /> : <Download />} Download
            </button>
          ) : null}
        </td>
      </tr>
    );
  }

  getAllTableLines() {
    return this.props.tableLines.map((t, i) => {
      return this.getTableLine(t.img, t.title, t.description, t.url, t.downloadLink, false, i);
    });
  }

  render() {
    return (
      <section className="MediaSection">
        <div className="content">
          <div className="title">
            <div>{this.props.subtitle}</div>
            <h2>{this.props.title}</h2>
          </div>
          <table cellPadding="0" cellSpacing="0">
            <tbody>{this.getAllTableLines()}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

MediaSection.propTypes = checkProps({
  title: PropTypes.node,
  subtitle: PropTypes.node,
  tableLines: PropTypes.array
});

MediaSection.defaultProps = {
  tableLines: []
};

export default withRouter(MediaSection);
