import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { noop } from '../../util/utilFn';

import checkProps from '../../util/check-props';

export default class BasicButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <div className={`BasicButton ${this.props.isSubmit ? 'submit' : ''} ${this.props.className}`}>
        <button
          type={this.props.isSubmit ? 'submit' : ''}
          disabled={this.props.disabled}
          className={this.props.type}
          onClick={() => {
            if (!this.props.disabled) this.props.onClick();
          }}
        >
          {this.props.label}
        </button>
      </div>
    );
  }
}

BasicButton.propTypes = checkProps({
  onClick: PropTypes.func,
  label: PropTypes.node,
  type: PropTypes.string,
  isSubmit: PropTypes.bool,
  disabled: PropTypes.bool
});

BasicButton.defaultProps = {
  onClick: noop,
  isSubmit: false,
  disabled: false
};
