import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import './style.scss';

import checkProps from '../../util/check-props';

export default class BigTitle extends React.PureComponent {
  render() {
    return (
      <div className="BigTitle">
        <h2>{parse(this.props.title)}</h2>
        <h3>{parse(this.props.subtitle)}</h3>
      </div>
    );
  }
}

BigTitle.propTypes = checkProps({
  title: PropTypes.string,
  subtitle: PropTypes.node
});

BigTitle.defaultProps = {};
