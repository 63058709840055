import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './App.scss';
import { resize } from './store/modules/resizing';
import MainMenu from './components/MainMenu';
import Login from './routes/Login';
import ForgotPassword from './routes/ForgotPassword';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import Home from './routes/Home';
import About from './routes/About';
import Artwork from './routes/Artwork';
import Products from './routes/Products';
import FAQ from './routes/FAQ';
import Footer from './components/Footer';
import Media from './routes/Media';
import Sustainability from './routes/Sustainability';
import { tween } from 'shifty';
import OrientationPortrait from './components/OrientationPortrait';
import Elf from './routes/Elf';
import { JSONTOPREFETCH, API_URL, CACHE_NAME, staticAssets, SITECONFIG } from './data/settings';
import { toggleDownloadApp, pwaNeedsUpdate } from './store/modules/downloadApp';
import * as serviceWorker from './serviceWorker';
import BasicButton from './components/BasicButton';
import { checkAPIAccessible, detectIE } from './util/utilFn';
import TermOfUse from './routes/TermOfUse';
import CookiePolicy from './routes/CookiePolicy';
import Logistics from './routes/Logistics';
import GoogleAnalytics from './components/GoogleAnalytics';
import OEM from './routes/OEM';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPortrait: false,
      showDownloadPopup: false,
      askCookies: true,
      showIEPopup: true,
      reLogin: false,
      globalInfo: null
    };
    this.currentRegistration = null;
  }

  componentDidMount() {
    if (window.sessionStorage.getItem('ask-cookies') && window.sessionStorage.getItem('ask-cookies') !== 'null') {
      this.setState({ askCookies: false });
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.orientationChange);
    this.transitionTO = -1;
    this.handleResize();
    this.checkOrientation();

    // When the service worker register, download all external resources
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        this.currentRegistration = registration;
        if (this.props.isAppDownloading) {
          console.log('service worker ready... Getting api server assets...');
          this.getAllExternalData(registration);
        }
      });

      if (navigator.serviceWorker && navigator.serviceWorker.controller) {
        this.fetchGlobalInfo({});
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAppDownloading !== this.props.isAppDownloading) {
      if (this.props.isAppDownloading) {
        if (navigator.serviceWorker && navigator.serviceWorker.controller) {
          console.log('already a service worker lets update it ...');
          if (this.currentRegistration !== null) {
            let token = this.props.token;
            if (token === null) {
              token = sessionStorage.getItem('jwtToken');
            }
            if (!token || token === null || token === 'null') {
              console.log('need to log before updating');
              this.setState({ reLogin: true }, () => {
                window.setTimeout(() => {
                  this.setState({ showDownloadPopup: false, reLogin: false }, () => {
                    this.props.toggleDownloadApp(false);
                    this.router.history.push('/login');
                  });
                }, 3000);
              });
            } else {
              this.currentRegistration.update().then(() => {
                this.getAllExternalData(this.currentRegistration).then(() => {
                  console.log('all updated refreshing page...');
                  window.location.reload();
                });
              });
            }
          }
        }
        this.registerServiceWorker();
        this.setState({ showDownloadPopup: true });
      }
    }

    if (prevProps.token !== this.props.token) {
      if (this.props.token !== null && this.props.token !== 'null') {
        this.fetchGlobalInfo({ Authorization: `Bearer ${this.props.token}` });
      }
    }
  }

  fetchGlobalInfo(authorization) {
    fetch(API_URL + SITECONFIG, {
      headers: {
        ...authorization
      }
    })
      .then(response => {
        return response.json();
      })
      .then(data => {
        if (data.code && data.code === 401) {
          console.error('error loading global info');
          window.setTimeout(() => {
            console.log(this.router);
            // this.router.history.push('/login');
          }, 1000);
        } else if (data.code && data.code !== 200) {
          console.error('error loading global info');
          // console.log('go back to login', this.router);
          // this.router.history.push('/login');
        } else {
          this.setState({ globalInfo: data });

          // Check if any of the files in the service worker needs an update
          if ('serviceWorker' in navigator) {
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
              checkAPIAccessible().then(isAccessible => {
                if (isAccessible) {
                  this.checkAllFileHeads().then(needsUpdate => {
                    if (needsUpdate) {
                      this.props.pwaNeedsUpdate();
                    }
                  });
                }
              });
            }
          }
        }
      });
  }

  getAllExternalData(registration) {
    return new Promise((resolve, reject) => {
      window.caches.open(CACHE_NAME).then(cache => {
        Promise.all(this.getAllExternalJSONData(cache)).then(() => {
          const msgChan = new MessageChannel();
          msgChan.port1.onmessage = event => {
            if (event.data.error) {
              console.error(event.data.error);
              reject();
            } else {
              localStorage.getItem('PwaUpdate');
              this.props.toggleDownloadApp(false);
              resolve();
            }
          };
          const allAssets = staticAssets.concat(this.state.globalInfo.remote_assets);
          localStorage.setItem('PwaUpdate', Math.floor(Date.now() / 1000));

          registration.active.postMessage(allAssets, [msgChan.port2]);
        });
      });
    });
  }

  getAllExternalJSONData(cache) {
    return JSONTOPREFETCH.map(j => {
      let finalURL = API_URL;
      if (j == '') {
        finalURL = finalURL.substring(0, finalURL.length - 1);
      }
      let token = sessionStorage.getItem('jwtToken');
      if (token === null || token === 'null') {
        token = this.props.token;
      }

      fetch(finalURL + j, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(response => {
        if (response.status === 200) {
          cache.put(finalURL + j, response.clone());
        }
        Promise.resolve();
      });
    });
  }

  checkAllFileHeads() {
    let finalURL = API_URL;
    const jsons = JSONTOPREFETCH.map(j => {
      if (j == '') {
        finalURL = finalURL.substring(0, finalURL.length - 1);
      }
      return finalURL + j;
    });
    const allFiles = staticAssets
      .concat(this.state.globalInfo.remote_assets)
      .concat(jsons)
      .filter(f => f.indexOf(SITECONFIG) < 0);
    let needsUpdate = false;
    return Promise.all(
      allFiles.map(f => {
        return fetch(f, {
          method: 'HEAD'
        }).then(
          r => {
            const swDate = parseInt(localStorage.getItem('PwaUpdate')) || 0;
            const rDate = new Date(r.headers.get('Last-Modified'));
            if (Math.floor(rDate.getTime() / 1000) > swDate) {
              console.log(f, localStorage.getItem('PwaUpdate'), Math.floor(rDate.getTime() / 1000));
              needsUpdate = true;
            }
          },
          () => {
            console.error('cant fetch file head', f);
          }
        );
      })
    ).then(() => {
      return Promise.resolve(needsUpdate);
    });
  }

  registerServiceWorker = () => {
    serviceWorker.register({
      onSuccess: registration => {
        console.log('service registered', registration);
      },
      onUpdate: registration => {
        console.log('service updated', registration);
      }
    });
  };

  orientationChange = () => {
    this.checkOrientation();
  };

  checkOrientation = () => {
    const orientation = window.orientation;
    this.setState({ isPortrait: orientation === 0 || orientation === 180 });
  };

  handleResize = () => {
    this.props.resize(window.innerWidth, window.innerHeight);
  };

  setCurrentComponent = comp => {
    if (this.currentComponent) this.exitingComponent = this.currentComponent;
    this.currentComponent = comp;
  };

  getIEPopup() {
    return (
      <div className="popup ie">
        <div className="background-popup" />
        <div className="popup-content">
          <div className="text">
            <h2>
              <span>Warning</span>You are currently using Internet Explorer
            </h2>

            <p>For an optimal browsing experience, we recommend using one of the browsers offered below.</p>
            <ul>
              <li>
                <a href="https://www.google.com/chrome/">Chrome</a>
              </li>
              <li>
                <a href="https://www.mozilla.org/en-US/firefox/">Firefox</a>
              </li>
              <li>
                <a href="https://support.apple.com/en_US/downloads/safari">Safari</a>
              </li>
            </ul>
            <p>Click on a link to access the download page</p>
            <div className="buttons">
              <BasicButton
                onClick={() => {
                  this.setState({ showIEPopup: false });
                }}
                label="Close"
                type="red"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getDownloadPopup() {
    return (
      <div className="popup">
        <div className="background-popup" />
        <div className="popup-content">
          <div className="visual">
            <img alt="popup" src="/assets/images/common/image-pwa.jpg" />
          </div>
          <div className="text">
            <h2>
              {this.props.isAppDownloading && !this.state.reLogin
                ? 'Downloading your content'
                : this.state.reLogin
                ? 'You need to log in to update the site.'
                : 'Thank you, you can access your app offline now.'}
            </h2>

            <p>
              {this.props.isAppDownloading && !this.state.reLogin
                ? 'Please hold on until the full content of the site is downloaded on your device.'
                : ''}
              {this.state.reLogin ? 'You will be redirected shortly' : ''}
            </p>

            {!this.props.isAppDownloading && (
              <div className="buttons">
                <BasicButton
                  onClick={() => {
                    this.setState({ showDownloadPopup: false });
                  }}
                  label="Close"
                  type="red"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  getRouter() {
    return (
      <Router
        ref={router => {
          this.router = router;
        }}
      >
        <div>
          <Route
            render={({ location }) => {
              this.previousLoc = location.pathname;
              return (
                <TransitionGroup>
                  <Transition
                    exit={location.pathname === this.previousLoc}
                    unmountOnExit={true}
                    mountOnEnter={true}
                    timeout={1600}
                    key={location.key}
                    onExit={el => {
                      if (location.pathname === this.previousLoc) {
                        this.currentComponent.checkHash();
                        return;
                      }
                      this.currScroll =
                        (window.scrollY || window.pageYOffset) -
                        this.currentComponent.main.current.getBoundingClientRect().height;
                    }}
                    onExiting={el => {
                      clearTimeout(this.transitionTO);
                      if (location.pathname === this.previousLoc) return;
                      this.currentComponent.main.current.classList.add('coming-page', 'start');
                      tween({
                        from: {
                          s: this.currScroll
                        },
                        to: { s: 0 },
                        duration: (window.scrollY || window.pageYOffset) === 0 ? 1 : 500,
                        easing: 'easeInOutExpo',
                        step: state => {
                          window.scrollTo(0, state.s);
                        }
                      }).then(() => {
                        document.body.classList.add('page-transition');
                        this.exitingComponent.main.current.classList.add('exit-page');
                        this.transitionTO = setTimeout(() => {
                          this.exitingComponent.main.current.classList.add('go');
                          this.currentComponent.main.current.classList.add('go');
                        }, 100);
                      });
                    }}
                    onExited={el => {
                      if (location.pathname === this.previousLoc) return;
                      clearTimeout(this.transitionTO);
                      document.body.classList.remove('page-transition');
                      this.exitingComponent.main.current.classList.remove('exit-page', 'go');
                      this.currentComponent.main.current.classList.remove('coming-page', 'go', 'start');
                      this.currentComponent.checkHash();
                    }}
                  >
                    <Switch location={location}>
                      <Route
                        exact
                        path="/"
                        render={props => <Home onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/about"
                        render={props => <About onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/artwork"
                        render={props => <Artwork onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        path="/products"
                        render={props => <Products onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/faq"
                        render={props => <FAQ onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/media"
                        render={props => <Media onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/elf-changes"
                        render={props => <Elf onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/sustainability"
                        render={props => <Sustainability onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/logistics"
                        render={props => <Logistics onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/terms-of-use"
                        render={props => <TermOfUse onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/cookies-policy"
                        render={props => <CookiePolicy onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/login"
                        render={props => <Login onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                      <Route
                        exact
                        path="/password"
                        render={props => <ForgotPassword onSetCurrentComponent={this.setCurrentComponent} {...props} />}
                      />
                    </Switch>
                  </Transition>
                </TransitionGroup>
              );
            }}
          />
          {this.state.askCookies && (
            <div className="ask-cookies">
              <div className="cookie-inside">
                <p>
                  By accessing this website, you acknowledge and accept the use of cookies and trackers to help us
                  recognize your repeat visits and preferences.
                  <br />
                  For more information, please read our updated <a href="/cookies-policy">Privacy and Cookies Policy</a>
                </p>
                <BasicButton
                  label="OK"
                  onClick={() => {
                    this.setState({ askCookies: false });
                    window.sessionStorage.setItem('ask-cookies', true);
                  }}
                />
              </div>
            </div>
          )}

          {this.state.globalInfo !== null && (
            <Fragment>
              <MainMenu data={this.state.globalInfo.main_menu} />
              <Footer data={this.state.globalInfo.footer_menu} />
            </Fragment>
          )}
          {this.state.showDownloadPopup && this.getDownloadPopup()}
          {detectIE() !== false && this.state.showIEPopup && this.getIEPopup()}
          <GoogleAnalytics />
        </div>
      </Router>
    );
  }

  render() {
    return <div className="App">{this.state.isPortrait ? <OrientationPortrait /> : this.getRouter()}</div>;
  }
}

const mapStateToProps = state => {
  return {
    isAppDownloading: state.downloadApp.toggle,
    token: state.user.token
  };
};

const mapDispatchToProps = dispatch => ({
  resize: (width, height) => dispatch(resize(width, height)),
  toggleDownloadApp: toggle => dispatch(toggleDownloadApp(toggle)),
  pwaNeedsUpdate: () => dispatch(pwaNeedsUpdate())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
