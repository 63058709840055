import React from 'react';

const DropGold = props => (
  <svg viewBox="0 0 56 56" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>{`9C85F54C-B91D-4BDA-8C93-5E9DD89F9E1D`}</title>
    <defs>
      <path id="a" d="M0 .033h33.165v22.199H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle fill="#D9B59D" cx={27.932} cy={27.932} r={27.932} />
      <path
        d="M32.879 33.919c-1.746 3.085-5.673 4.177-8.771 2.438a6.426 6.426 0 0 1-2.443-2.431c-1.063-1.841-1.084-3.909-.062-5.677l5.665-7.84 5.666 7.863c1.02 1.755 1 3.811-.055 5.647zm-5.074-15.03a.668.668 0 0 0-.536-.281.669.669 0 0 0-.536.281l-6.22 8.613a.688.688 0 0 0-.035.055c-1.265 2.16-1.249 4.787.04 7.024 2.115 3.713 6.85 5.017 10.579 2.912a7.744 7.744 0 0 0 2.928-2.92c1.284-2.232 1.296-4.845.034-6.992-.01-.018-.022-.039-.033-.055l-6.22-8.636z"
        fill="#FFF"
      />
      <path
        d="M30.996 30.22a.66.66 0 0 0-.66.658 3.102 3.102 0 0 1-3.115 3.082.66.66 0 0 0-.66.658.66.66 0 0 0 .66.658c2.443.003 4.427-1.965 4.436-4.398a.66.66 0 0 0-.66-.658"
        fill="#FFF"
      />
      <path
        d="M39.324 30.124l3.174-4.82 3.173 4.82h-6.347zm3.726-6.415a.662.662 0 0 0-1.105 0l-4.397 6.678a.687.687 0 0 0-.029.691.678.678 0 0 0 .582.362h3.553a15.138 15.138 0 0 1-5.173 9.267C33.798 43 30.43 44.305 27.24 44.305c-2.08 0-4.255-.564-6.291-1.62a15.646 15.646 0 0 1-5.367-4.588.662.662 0 0 0-.922-.143.656.656 0 0 0-.144.919 16.97 16.97 0 0 0 5.822 4.974c2.224 1.155 4.61 1.766 6.902 1.766 3.5 0 7.182-1.413 10.101-3.906a16.61 16.61 0 0 0 5.65-10.267h3.904a.68.68 0 0 0 .552-1.045L43.05 23.71z"
        fill="#FFF"
      />
      <g transform="translate(6.983 11.476)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M5.057 20.342l-3.174-4.82H8.23l-3.173 4.82zm4.978-5.774a.677.677 0 0 0-.581-.362H5.9a15.138 15.138 0 0 1 5.173-9.268c2.683-2.291 6.051-3.597 9.241-3.597 2.08 0 4.255.563 6.29 1.62a15.645 15.645 0 0 1 5.368 4.588.662.662 0 0 0 .922.143.656.656 0 0 0 .144-.92 16.968 16.968 0 0 0-5.822-4.974C24.992.644 22.606.033 20.314.033c-3.5 0-7.182 1.413-10.101 3.906a16.61 16.61 0 0 0-5.649 10.267H.66a.68.68 0 0 0-.553 1.044l4.397 6.682a.662.662 0 0 0 1.105.004l4.397-6.686a.67.67 0 0 0 .029-.682z"
          fill="#FFF"
          mask="url(#b)"
        />
      </g>
    </g>
  </svg>
);

export default DropGold;
