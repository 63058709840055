import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';
import BasicButton from '../BasicButton';
import NumberTitle from '../NumberTitle';
import parse from 'html-react-parser';

class DiscoverSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  buildProductTable(infos) {
    const perc = (1 / infos.nbCols) * 100 + '%';
    const style = { width: perc };
    return (
      <Fragment>
        <div className="logo">
          <img alt="" src={infos.logo} />
        </div>
        <div className="half-square-wrapper">
          <div />
        </div>
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr>
              {infos.th.map((v, i) => (
                <td key={i} style={style}>
                  {parse(v)}
                </td>
              ))}
            </tr>
            {infos.values[0].map((v, i) => {
              return (
                <tr key={i}>
                  {infos.th.map((t, j) => (
                    <td key={j} style={style}>
                      {infos.values[j][i] && (
                        <button
                          onClick={() => {
                            this.props.history.push(infos.values[j][i].url);
                          }}
                        >
                          {parse(infos.values[j][i].label || '')}
                        </button>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Fragment>
    );
  }

  render() {
    return (
      <section className="DiscoverSection">
        <div className="content">
          <div className="top-arrow" />
          <NumberTitle number={this.props.number} numberStyle={this.props.numberStyle} title={this.props.title} />
          <h4>{this.props.subtitle}</h4>
          <div className="sub-products">
            <div className="product">
              <div className="logo">
                <img alt="" src={this.props.data[0].logo} />
              </div>
              <div className="button-wrapper">
                <BasicButton
                  label={this.props.data[0].buttonLabel}
                  onClick={() => {
                    this.props.history.push(this.props.data[0].buttonUrl);
                  }}
                />
              </div>
            </div>
            <div className="product">{this.buildProductTable(this.props.data[1])}</div>
            <div className="product">{this.buildProductTable(this.props.data[2])}</div>
          </div>
          <div className="sub-products big">
            <div className="product total-special-menu">{this.buildProductTable(this.props.data[3])}</div>
          </div>
          <div className="sub-products big elf">
            <div className="product total-special-menu">{this.buildProductTable(this.props.data[4])}</div>
          </div>
        </div>
      </section>
    );
  }
}

DiscoverSection.propTypes = checkProps({
  number: PropTypes.string,
  numberStyle: PropTypes.object,
  data: PropTypes.array,
  title: PropTypes.node,
  subtitle: PropTypes.node
});

DiscoverSection.defaultProps = {
  data: []
};

export default withRouter(DiscoverSection);
