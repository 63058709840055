import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.scss';

import checkProps from '../../util/check-props';
import BasicButton from '../BasicButton';
import { goTo } from '../../util/utilFn';

class CarrouselItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <section className="CarrouselItem">
        <div className="content">
          <div className="text">
            <h3 className="regular-title">{this.props.title}</h3>
            <h4 className="regular-subtitle">{this.props.subtitle}</h4>
            <p className="regular-text">{this.props.text}</p>
            {this.props.buttonLabel && this.props.buttonURL && (
              <BasicButton
                label={this.props.buttonLabel}
                onClick={() => {
                  goTo(this.props.buttonURL, this.props.history);
                }}
              />
            )}
          </div>
          <div className={`visual ${this.props.isBeforeAfter ? 'before-after' : ''}`}>{this.props.visualCtn}</div>
        </div>
      </section>
    );
  }
}

CarrouselItem.propTypes = checkProps({
  title: PropTypes.string,
  subtitle: PropTypes.node,
  text: PropTypes.node,
  isBeforeAfter: PropTypes.bool,
  slideNumber: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonURL: PropTypes.string,
  visualCtn: PropTypes.node
});

CarrouselItem.defaultProps = {};

export default withRouter(CarrouselItem);
