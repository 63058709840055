import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';

const smoothstep = require('smoothstep');

export default class ProductDesignFreeElement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    let op =
      smoothstep(this.props.percStart - 0.05, this.props.percStart, this.props.perc) *
      smoothstep(this.props.percStop, this.props.percStop - 0.05, this.props.perc);
    if (isNaN(op)) op = 0;
    return (
      <div className="ProductDesignFreeElement" style={{ ...this.props.style }}>
        <div
          className="popElement"
          style={{
            visibility: op === 0 ? 'hidden' : 'visible',
            opacity: op
          }}
        >
          {this.props.content}
        </div>
      </div>
    );
  }
}

ProductDesignFreeElement.propTypes = checkProps({
  style: PropTypes.object,
  content: PropTypes.node,
  percStart: PropTypes.number,
  percStop: PropTypes.number,
  perc: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number
});

ProductDesignFreeElement.defaultProps = {
  perc: 0,
  percStart: 0,
  percStop: 1,
  top: 0,
  right: 0
};
