import React from 'react';

const PlusButton = props => (
  <svg viewBox="0 0 43 43" {...props}>
    <title>{`C9BF63A2-F170-4103-BB1B-2D15A8F5C175`}</title>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <ellipse
        stroke="#666"
        strokeWidth={3}
        fill="#FFF"
        cx={19.642}
        cy={19.646}
        rx={19.642}
        ry={19.646}
      />
      <path
        stroke="#FFF"
        strokeWidth={2}
        fill="#000"
        fillRule="nonzero"
        d="M21.122 12.37H17.62v5.254h-5.253v3.503h5.253v5.254h3.502v-5.254h5.254v-3.503h-5.254z"
      />
    </g>
  </svg>
);

export default PlusButton;
