import React from 'react';

const ArrowParallaxTop = props => (
  <svg viewBox="0 0 7 85" {...props}>
    <title>{`9573A410-CFD3-45C0-B862-4E0CB5899165`}</title>
    <g fill="none" fillRule="evenodd">
      <path fill="#666" d="M3.461 0l2.992 3.838H.5z" />
      <path d="M3.508 2.949v80.855" stroke="#666" strokeLinecap="square" />
    </g>
  </svg>
);

export default ArrowParallaxTop;
