import React, { Fragment } from 'react';
import HeaderHero from '../components/HeaderHero';
import PictoSection from '../components/PictoSection';
import VisualTextSection from '../components/VisualTextSection';
import DiscoverSection from '../components/DiscoverSection';
import MultipleTabsSection from '../components/MultipleTabsSection';
import TestimonySection from '../components/TestimonySection';
import RegularCarrouselSection from '../components/RegularCarrouselSection';
import HeroVisual from '../components/HeroVisual';
import MapSection from '../components/MapSection';
import ZoomPackageSection from '../components/ZoomPackageSection';
import LogoBeforeAfterSection from '../components/LogoBeforeAfterSection';
import ProductDesignSection from '../components/ProductDesignSection';
import MediaSection from '../components/MediaSection';
import SustainBeforeAfter from '../components/SustainBeforeAfter';
import ElfArtwork from '../components/ElfArtwork';
import OemArtwork from '../components/OemArtwork';
import WysiwygSection from '../components/WysiwygSection';
import BeforeAfter from '../components/BeforeAfter';
import FullWidthPictureSection from '../components/FullWidthPictureSection';

const HeaderHeroType = 'HeaderHero';
const PictoSectionType = 'PictoSection';
const VisualTextSectionType = 'VisualTextSection';
const DiscoverSectionType = 'DiscoverSection';
const MultipleTabsSectionType = 'MultipleTabsSection';
const TestimonySectionType = 'TestimonySection';
const RegularCarrouselSectionType = 'RegularCarrouselSection';
const ProductDesignSectionType = 'ProductDesignSection';
const LogoBeforeAfterSectionType = 'LogoBeforeAfterSection';
const ZoomPackageSectionType = 'ZoomPackageSection';
const HeroVisualType = 'HeroVisual';
const MapSectionType = 'MapSection';
const MediaSectionType = 'MediaSection';
const SustainBeforeAfterType = 'SustainBeforeAfter';
const TitleSectionType = 'TitleSection';
const WysiwygSectionType = 'WysiwygSection';
const BeforeAfterSectionType = 'BeforeAfterSection';
const FullWidthPictureSectionType = 'FullWidthPictureSection';

export default (compList, isElfPage) => {
  return compList.map((c, i) => {
    let comp;
    switch (c.type) {
      case HeaderHeroType: {
        comp = <HeaderHero key={i} {...c.content} />;
        break;
      }
      case PictoSectionType: {
        comp = <PictoSection key={i} {...c.content} />;
        break;
      }
      case VisualTextSectionType: {
        comp = <VisualTextSection key={i} {...c.content} />;
        break;
      }
      case DiscoverSectionType: {
        comp = <DiscoverSection key={i} {...c.content} />;
        break;
      }
      case MultipleTabsSectionType: {
        comp = <MultipleTabsSection key={i} {...c.content} />;
        break;
      }
      case TestimonySectionType: {
        comp = <TestimonySection key={i} {...c.content} />;
        break;
      }
      case RegularCarrouselSectionType: {
        comp = <RegularCarrouselSection key={i} {...c.content} />;
        break;
      }
      case HeroVisualType: {
        if (isElfPage) {
          comp = (
            <Fragment key={i}>
              <ElfArtwork />
              <HeroVisual {...c.content} />
            </Fragment>
          );
        } else {
          comp = <HeroVisual key={i} {...c.content} />;
        }

        break;
      }
      case MapSectionType: {
        comp = <MapSection key={i} {...c.content} />;
        break;
      }
      case ZoomPackageSectionType: {
        comp = <ZoomPackageSection key={i} {...c.content} />;
        break;
      }
      case LogoBeforeAfterSectionType: {
        comp = <LogoBeforeAfterSection key={i} {...c.content} />;
        break;
      }
      case ProductDesignSectionType: {
        comp = <ProductDesignSection key={i} {...c.content} />;
        break;
      }
      case MediaSectionType: {
        comp = <MediaSection key={i} {...c.content.sections[0]} />;
        break;
      }
      case SustainBeforeAfterType: {
        comp = <SustainBeforeAfter key={i} {...c.content} />;
        break;
      }
      case WysiwygSectionType: {
        comp = <WysiwygSection key={i} {...c.content} />;
        break;
      }
      case FullWidthPictureSectionType: {
        comp = <FullWidthPictureSection key={i} {...c.content} />;
        break;
      }
      case BeforeAfterSectionType: {
        /* <BeforeAfter
        domContent={true}
        addClassName="oil-compare"
        beforeContent={
          <div className="before-oil">
            <div>Before</div>
            <img src="/assets/placeholders/images/concept.png" alt="" />
          </div>
        }
        afterContent={
          <div className="after-oil">
            <div>After</div>
            <img src="/assets/placeholders/images/concept.png" alt="" />
          </div>
        }
      /> */
        const ctnBA = {
          ...c.content,
          domContent: true,
          addClassName: 'oil-compare'
        };
        comp = <BeforeAfter key={i} {...ctnBA} />;
        break;
      }
      case TitleSectionType: {
        comp = (
          <section key={i} className="content-title">
            <div className="content">
              <h3 className="regular-title sub-content">{c.content.title}</h3>
              <h4 className="regular-subtitle sub-content">{c.content.subtitle}</h4>
            </div>
          </section>
        );
        break;
      }
      default: {
        comp = null;
        break;
      }
    }

    if (c.hash) {
      return (
        <Fragment key={i}>
          <a href={`#${c.hash}`} className="anchor-link" name={c.hash} id={c.hash}>
            {c.hash}
          </a>
          {comp}
        </Fragment>
      );
    }

    return comp;
  });
};
