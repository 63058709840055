import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.scss';
import { tween } from 'shifty';

import checkProps from '../../util/check-props';
import checkToken from '../../util/checkToken';
import componentBuilder from '../../util/componentBuilder';
import { loginFromLocalStorage, tokenExpired } from '../../store/modules/user';

class About extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      components: (
        <div className="loading">
          <div className="text">Loading...</div>
        </div>
      )
    };
    this.main = React.createRef();
    this.checkHashTO = -1;
  }

  componentDidMount() {
    this.props.onSetCurrentComponent(this);
    checkToken('about.json', this.props).then(
      data => {
        const comps = <Fragment>{componentBuilder(data.components)}</Fragment>;
        this.setState({
          components: comps
        });
      },
      () => {
        console.warn('token expired, return to login');
        this.props.tokenExpired();
        this.props.history.push('/login');
      }
    );
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && this.props.location.hash) {
      clearTimeout(this.checkHashTO);
      // this.checkHash();
    }
  }

  checkHash() {
    if (this.props.location.hash && this.props.location.hash.length > 0) {
      const element = document.querySelector(this.props.location.hash);

      if (element) {
        const start = window.pageYOffset;
        const y = start + element.getBoundingClientRect().top;
        const diff = y;
        tween({
          from: {
            s: 0
          },
          to: { s: 1 },
          duration: 1000,
          easing: 'easeInOutExpo',
          step: state => {
            window.scrollTo(
              0,
              (window.pageYOffset - element.getBoundingClientRect().height) * (1 - state.s) + state.s * diff
            );
          }
        });
      }
    }
  }

  render() {
    return (
      <div ref={this.main} className="About">
        {this.state.components}
      </div>
    );
  }
}

About.propTypes = checkProps({
  onSetCurrentComponent: PropTypes.func,
  token: PropTypes.string,
  dispatch: PropTypes.func,
  loginFromLocalStorage: PropTypes.func,
  tokenExpired: PropTypes.func
});

About.defaultProps = {};

const mapStateToProps = state => {
  return {
    token: state.user.token
  };
};

const mapDispatchToProps = dispatch => ({
  loginFromLocalStorage: token => dispatch(loginFromLocalStorage(token)),
  tokenExpired: () => dispatch(tokenExpired())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(About)
);
