import React from 'react';
import PropTypes from 'prop-types';
import { propTypes, withFormsy } from 'formsy-react';

import './style.scss';

class BasicInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showError: false };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    nextProps.getValue();
  }

  changeValue = event => {
    this.props.setValue(event.currentTarget.value);
  };

  showError = () => {
    this.setState({ showError: true });
  };

  render() {
    const errorMessage = this.props.getErrorMessage();
    return (
      <div className={`BasicInput ${this.props.className} ${errorMessage && errorMessage.length > 0 ? 'error' : ''}`}>
        {/* <label htmlFor={this.props.name}>{this.props.label}</label> */}
        <input
          name={this.props.name}
          id={this.props.name}
          onChange={this.changeValue}
          onFocus={this.showError}
          type={this.props.type}
          value={this.props.getValue() || ''}
          placeholder={`${this.props.label}${this.props.required ? '*' : ''}`}
        />
        <hr />
        {this.state.showError ? <span className="error-message">{errorMessage}</span> : null}
      </div>
    );
  }
}

BasicInput.propTypes = {
  ...propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string
};

BasicInput.defaultProps = {
  className: '',
  label: '',
  type: 'text'
};

export default withFormsy(BasicInput);
