import React from 'react';

const TotalLogo = props => (
  <svg viewBox="0 0 183 46" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} preserveAspectRatio="xMidYMin meet">
    <title>{`276009D2-B589-49C3-9DF1-BA7AC75F861C`}</title>
    <defs>
      <path id="a" d="M.482.147h34.135v45.856H.482z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M91.398 32.369c-4.673 0-7.032-1.788-7.032-5.354 0-3.604 2.36-5.407 7.032-5.407 4.662 0 7.044 1.803 7.044 5.407 0 3.566-2.382 5.354-7.044 5.354m0-15.423c-8.542 0-13.436 3.67-13.436 10.069 0 6.333 4.894 10 13.436 10 8.531 0 13.415-3.667 13.415-10 0-6.4-4.884-10.069-13.415-10.069M129.866 17.437H106.56v4.28h8.091v13.29c0 1.266 1.935 1.724 3.57 1.724 1.633 0 3.567-.435 3.567-1.725v-13.29h8.078v-4.279zM172.912 32.105c-1.977 0-3.336-.33-4.078-.945-.964-.82-1.075-2.036-1.101-2.96V18.82c0-1.252-1.836-1.659-3.402-1.659-1.588 0-3.436.407-3.436 1.639v9.083c0 3.466 1.114 5.898 3.33 7.23 2.736 1.588 7.712 1.53 12.494 1.47h5.98v-4.479h-9.787zM80.922 11.633H52.668v4.72h9.743v14.33c0 3.385 2.847 5.688 7.676 6.088V16.353h10.835v-4.72zM138.818 28.436a35.113 35.113 0 0 1 4.73-5.734 36.82 36.82 0 0 1 4.696 5.734h-9.426zm6.79-10.768c-.602-.412-1.234-.506-2.06-.531-.844.025-1.503.119-2.087.531-1.846 1.33-10.77 10.3-12.342 18.145l-.18.775h6.313l.116-.56c.264-1.109.669-2.315 1.224-3.556h13.88c.552 1.24.97 2.447 1.204 3.556l.134.56h6.322l-.167-.775c-1.593-7.846-10.51-16.815-12.357-18.145z"
        fill="#E3042E"
      />
      <path
        d="M41.928 25.567a3.426 3.426 0 0 0-.104-.284c-3.278 3.739-11.325 6.163-18.565 5.624-4.264-.317-12.163-2.523-16.148-4.967 4.212 4.036 9.135 7.102 14.72 8.757 6.627 1.97 13.695 1.836 17.678-.239 2.924-1.97 3.587-5.493 2.419-8.89"
        fill="#F39600"
      />
      <g transform="translate(5.055 -.142)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M27.759 2.17c13.05 7.706 5.282 31.961-5.103 39.755-9.663 7.246-19.947 3.905-22.174-2.78 1.948 2.896 5.81 3.955 9.243 2.8 9.541-3.824 14.453-21.113 15.088-27.117.425-5.167-.247-8.639-2.042-10.824-2.057-2.172-5.202-3.226-9.812-1.78 3.17-2.602 9.958-2.911 14.8-.054"
          fill="#164193"
          mask="url(#b)"
        />
      </g>
      <path
        d="M40.115 34.115c.046-.03.104-.067.156-.098.007-.011.02-.014.025-.019a6.23 6.23 0 0 0 1.126-.935c5.078-5.151 6.156-19.484-6.358-26.272C30.613 4.373 20.258.988 9.934 6.21c-3.418 1.723-6.94 5.308-8.84 10.178 1.401-2.313 3.824-3.88 8.598-4.554 4.761-.602 11.329.139 17.75 2.407 4.14 1.528 7.49 3.414 10.11 5.551 2.16 1.851 3.55 3.616 4.272 5.49.037.094.074.188.104.287 1.168 3.397.505 6.918-2.42 8.892-.002.003.008-.001 0 0a8.85 8.85 0 0 0 .607-.345"
        fill="#E3042E"
      />
      <path
        d="M2.71 24.631c.24.383.782 1.032 1.133 1.44 2.512-5.9 16.252-9.732 20.303-9.685-9.104-2.933-18.028-1.533-21.613 1.578-1.303 1.453-1.71 3.63.176 6.667"
        fill="#F39600"
      />
      <path
        d="M29.505 38.013c-7.317-.407-17.971-3.23-25.52-11.78-.038-.055-.087-.105-.142-.163-.352-.407-.894-1.058-1.133-1.44-1.886-3.034-1.48-5.213-.177-6.662H2.53a6.486 6.486 0 0 0-.85.906C-.258 21.371-.489 25.427.827 29.725c2.877 9.335 15.458 14.952 25.668 13.41 6.523-.984 10.611-4.657 12.313-6.157-3.696 1.052-6.426 1.198-9.304 1.035"
        fill="#4A95D1"
      />
    </g>
  </svg>
);

export default TotalLogo;
