import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './style.scss';

import checkProps from '../../util/check-props';
import ProductDesignPopElement from '../ProductDesignPopElement';
import ProductDesignFrame from '../ProductDesignFrame';
import ArrowParallaxTop from '../SvgComponents/ArrowParallaxTop/ArrowParallaxTop';
import ArrowParallaxBottom from '../SvgComponents/ArrowParallaxBottom/ArrowParallaxBottom';
import ProductDesignFreeElement from '../ProductDesignFreeElement';

class ElfArtwork extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { perc: 0, scroll: 0, fullHeight: 0, toggleActive: '' };
    this.rafID = -1;
    this.nbFrame = 6;
    this.main = React.createRef();
    this.parallaxWrapper = React.createRef();
    this.topContent = React.createRef();
  }

  componentDidMount() {
    this.updateFrame();
  }

  componentWillReceiveProps(nextProps) {}

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.viewPortWidth !== this.props.viewPortWidth ||
      prevProps.viewPortHeight !== this.props.viewPortHeight ||
      this.state.fullHeight === 0
    ) {
      this.setState({ fullHeight: this.props.viewPortHeight * this.nbFrame * 2 });
    }
  }

  componentWillUnmount() {
    this.setState({ fullHeight: 0 });
    cancelAnimationFrame(this.rafID);
  }

  updateFrame = () => {
    this.rafID = requestAnimationFrame(this.updateFrame);
    this.updatePosition();
  };

  updatePosition = () => {
    const scroll =
      (window.scrollY || window.pageYOffset) - (this.main.current.offsetTop + this.topContent.current.offsetHeight);
    const perc = Math.min(1, Math.max(0, (scroll + window.innerHeight) / this.parallaxWrapper.current.offsetHeight));
    this.setState({ scroll, perc });
  };

  getFirstPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          percStart={-1}
          percStop={0.2}
          scroll={this.state.scroll}
          image="/assets/images/product-design/can-00.png"
          backTitle={
            <Fragment>
              BOTTLE
              <br />
              DESIGN
            </Fragment>
          }
          className="silver"
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.01}
          percStop={0.17}
          className="small"
          scroll={this.state.scroll}
          content={
            <Fragment>
              <div className="picto">
                <img alt="" src="/assets/images/product-design/elf-bulb.png" />
              </div>
              <p>ELF products will be bottled with the same design of TOTAL ones.</p>
              <hr />
            </Fragment>
          }
        />
      </Fragment>
    );
  }

  getSecondPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.2}
          percStop={0.4}
          image="/assets/images/product-design/can-elf.png"
          backTitle={
            <Fragment>
              Color
              <br />
              Strategy
            </Fragment>
          }
          className="elf"
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.22}
          percStop={0.37}
          scroll={this.state.scroll}
          content={
            <Fragment>
              <div className={`picto`}>
                <img alt="" src="/assets/images/product-design/squares-elf.png" />
              </div>
              <p>The color of ELF bottles will remain the same.</p>
              <hr />
              <div className={`navigation`}>
                <span>1 colors: </span>
                <ul>
                  <li />
                </ul>
                <div className="color-name">Dark blue.</div>
              </div>
            </Fragment>
          }
        />
      </Fragment>
    );
  }

  toggleFuturLabel = () => {
    if (this.state.toggleActive.length === 0) {
      this.setState({ toggleActive: 'active' });
    } else {
      this.setState({ toggleActive: '' });
    }
  };

  getThirdPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.4}
          percStop={0.6}
          image="/assets/images/product-design/can-elf.png"
          backTitle={
            <Fragment>
              Label Design
              <br />
              Evolution
            </Fragment>
          }
          isOverflow={true}
          extraContent={
            <div className="label-images">
              <img alt="" src="/assets/images/product-design/can-current-label-elf.png" />
            </div>
          }
          className="elf"
        />
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.6}
          percStop={0.8}
          image="/assets/images/product-design/can-elf.png"
          backTitle={
            <Fragment>
              Label Design
              <br />
              Evolution
            </Fragment>
          }
          isOverflow={true}
          extraContent={
            <div className="label-images">
              <img alt="" src="/assets/images/product-design/can-futur-label-elf.png" />
            </div>
          }
          className="elf"
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.42}
          percStop={0.77}
          scroll={this.state.scroll}
          className="medium"
          content={
            <Fragment>
              <div className={`picto`}>
                <img alt="" src="/assets/images/product-design/elf-label.png" />
              </div>
              <p>
                Labels color and graphic charter will remain the same as the current ones. However, their shape will be
                adapted to the new dimensions of the label display in Wave bottles.
                <br />
                <br />
                Average label size improvement: 10%
              </p>
              <hr />
              <div className="toggle-btn">
                <button className={this.state.perc > 0.52 ? 'active' : ''}>
                  <div className="handle" />
                  <div className="label">
                    <div>{this.state.perc > 0.52 ? 'Futur Label' : 'Current Label'} </div>
                  </div>
                </button>
              </div>
            </Fragment>
          }
        />
      </Fragment>
    );
  }

  getFourthPhase() {
    return (
      <Fragment>
        <ProductDesignFrame
          perc={this.state.perc}
          scroll={this.state.scroll}
          percStart={0.8}
          percStop={3}
          image="/assets/images/product-design/can-elf.png"
          backTitle={
            <Fragment>
              ERGONOMICS <br />
              &amp; MATERIALS{' '}
            </Fragment>
          }
          extraContent={
            <Fragment>
              <ProductDesignFreeElement
                perc={this.state.perc}
                percStart={0.9}
                percStop={3}
                style={{
                  top: '-50px',
                  left: '140px',
                  width: '270px'
                }}
                content={
                  <Fragment>
                    <img alt="" src="/assets/images/product-design/elf-handle.png" />
                  </Fragment>
                }
              />
              <ProductDesignFreeElement
                perc={this.state.perc}
                percStart={0.8}
                percStop={0.9}
                style={{
                  top: '-140px',
                  left: '-80px',
                  width: '270px'
                }}
                content={
                  <Fragment>
                    <img alt="" src="/assets/images/product-design/zoom-cap-elf.png" />
                  </Fragment>
                }
              />
            </Fragment>
          }
          className="elf"
        />

        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.9}
          percStop={3}
          scroll={this.state.scroll}
          className="medium"
          content={
            <Fragment>
              <div className={`picto  picto-height`}>
                <img alt="" src="/assets/images/product-design/elf-jerican.png" />
              </div>
              <p>
                The big side handle facilitates the grabbing and carrying of the product, as well as the lubricant
                pouring.
              </p>
              <hr />
            </Fragment>
          }
        />
        <ProductDesignPopElement
          perc={this.state.perc}
          percStart={0.82}
          percStop={0.88} 
          scroll={this.state.scroll}
          content={
            <Fragment>
              <div className={`picto  picto-height`}>
                <img alt="" src="/assets/images/product-design/RING_PEEL_LINER.png" />
              </div>
              <p>
                RING PEEL LINER <br />
                Improves sealling and allows product authentication.
              </p>
              <hr />
              <div className={`picto  picto-height`}>
                <img alt="" src="/assets/images/product-design/FLEXPOUT_CAP.png" />
              </div>
              <p>FLEXSPOUT CAP <br />
                 Helps with pouring and precise flow.
              </p>
              <hr />
            </Fragment>
          }
        />
      </Fragment>
    );
  }

  getChapterNB() {
    if (this.state.perc < 0.2) {
      return '01';
    } else if (this.state.perc < 0.4) {
      return '02';
    } else if (this.state.perc < 0.8) {
      return '03';
    } else {
      return '04';
    }
  }

  getScroll(scroll) {
    let s = 0;
    if (!this.parallaxWrapper.current) return;
    if (scroll < 0) {
      s = -scroll;
    }
    const scrollBottom = scroll + window.innerHeight - this.parallaxWrapper.current.offsetHeight;
    if (scrollBottom > 0) {
      s = -scrollBottom;
    }
    return {
      transform: `translateY(${s}px)`
    };
  }

  render() {
    return (
      <div ref={this.main} className="ElfArtwork">
        <div className="content content-title" ref={this.topContent}>
          <h3 className="regular-title">
            NEW BOTTLE DESIGN <span>&amp;</span> COLOR STRATEGY
          </h3>
          <h4 className="regular-subtitle">Discover the new design evolution and its main guidelines</h4>
        </div>
        <div ref={this.parallaxWrapper} className="parallax-wrapper" style={{ height: this.state.fullHeight + 'px' }}>
          <div style={{ ...this.getScroll(this.state.scroll) }} className="fixed-container">
            {this.getFirstPhase()}
            {this.getSecondPhase()}
            {this.getThirdPhase()}
            {this.getFourthPhase()}
            <section className="static-elements">
              <div className="content">
                <div className="static-wrapper">
                  <div className="counter">
                    <div className="current-frame">{this.getChapterNB()}</div>
                    <hr />
                    <div className="total-frame">04</div>
                  </div>
                  <div className="arrows">
                    <ArrowParallaxTop />
                    <ArrowParallaxBottom />
                  </div>
                </div>
                <div className="side-instruction scrollInfo">Scroll To explore</div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

ElfArtwork.propTypes = checkProps({
  dispatch: PropTypes.func,
  viewPortWidth: PropTypes.number,
  viewPortHeight: PropTypes.number
});

ElfArtwork.defaultProps = {};

const mapStateToProps = state => {
  return {
    viewPortWidth: state.resizing.width,
    viewPortHeight: state.resizing.height
  };
};

export default connect(
  mapStateToProps,
  null
)(ElfArtwork);
