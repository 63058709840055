import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import checkProps from '../../util/check-props';
import VideoPlayer from '../VideoPlayer';

export default class HeroVisual extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { videoWidth: 0, videoHeight: 0, isPlaying: false };
    this.main = React.createRef();
    this.video = React.createRef();
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <section className="HeroVisual">
        {this.props.halfBackground ? <div className="rectangle-background" /> : null}
        <div className="content">
          <div className="small-content">
            <h3 className="regular-title">{this.props.title}</h3>
            <h4 className="regular-subtitle">{this.props.subtitle}</h4>
          </div>
          {this.props.videoSRC && this.props.posterSRC ? (
            <div className="video">
              <VideoPlayer posterSRC={this.props.posterSRC} videoSRC={this.props.videoSRC} />
            </div>
          ) : (
            <div className="visual">
              <img src={this.props.visualSRC} alt="main" />
            </div>
          )}
        </div>
      </section>
    );
  }
}

HeroVisual.propTypes = checkProps({
  dispatch: PropTypes.func,
  visualSRC: PropTypes.string,
  posterSRC: PropTypes.string,
  sectionHeight: PropTypes.string,
  videoSRC: PropTypes.string,
  halfBackground: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.node
});

HeroVisual.defaultProps = {};
